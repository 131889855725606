<template>
  <div class="main-container-hidden">
    <div class="main-container flexbox flex-lr">
      <div class="main-container-first-menu main-position-first">
        <div class="main-container-logo">
          <img :src="$imgPrefix($store.state.system_cfg.platformLogo, '/public/joint_food/admin/login-icon.png')" alt="logo" />
        </div>
        <ul class="main-container-first-menu-list">
          <li
            v-for="menuItem in menuList"
            :key="menuItem.name"
            @click="checkedFirstMenu(menuItem)"
            :class="
              currentActive &&
              currentActive.name === menuItem.name &&
              'main-container-first-menu-active'
            "
          >
            <i v-if="menuItem.icon" :class="menuItem.icon"></i>
            <span>{{ menuItem.title }}</span>
          </li>
        </ul>
      </div>
      <div class="main-container-right flexbox flex-tb">
        <div
          class="
            main-container-header
            flexbox
            flex-lr flex-align-center flex-justify-between
          "
        >
          <div class="main-container-header-tab">
            <el-tag
              v-for="tag in tabList"
              :key="tag.name"
              @close="handleCloseTab(tag)"
              @click="toPage(tag)"
              :closable="tabList.length > 1"
            >
              {{ tag.title }}
            </el-tag>
          </div>
          <div class="flexbox flex-lr main-container-header-right">
            <el-badge :value="unreadCount" :max="99" :hidden="unreadCount>0?false:true">
              <i class="el-icon-message" @click="noticeVisiable=true"></i>
            </el-badge>
            <el-dropdown
              class="main-container-header-dropdown"
              @command="handleCommand"
            >
              <span class="el-dropdown-link">
                {{ $store.state.userInfo.name }}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu
                slot="dropdown"
                class="main-container-dropdown-list"
              >
                <!-- <el-dropdown-item>账号信息</el-dropdown-item>
                <el-dropdown-item>帮助中心</el-dropdown-item> -->
                <el-dropdown-item command="changePass">修改密码</el-dropdown-item>
                <el-dropdown-item command="logout">退出登录</el-dropdown-item>
                <el-dropdown-item v-if="$hasAuthRoute('RecycleBin')" command="recycleBin">回收站</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="flexbox flex-lr main-container-wrap">
          <div
            class="main-position-secondary"
            v-if="
              currentActive &&
              currentActive.children &&
              currentActive.children.length
            "
          >
            <ul class="main-container-secondary-menu">
              <li
                v-for="item in currentActive.children"
                :key="item.name"
                class="main-container-secondary-menu-item"
                :class="{
                  'main-container-secondary-menu-active':
                    currentActiveItem && currentActiveItem.name === item.name,
                }"
                @click="jumpToPage(item)"
              >
                {{ item.title }}
              </li>
            </ul>
          </div>

          <div class="flex_1 main-container-content" :id="$route.name">
            <!-- :key="$route.fullPath"  -->
            <keep-alive :include="keepAliveCompList" :max="10">
              <router-view
                style="width: 100%; height: 100%; overflow-y: auto"
              />
            </keep-alive>
            <MenuBar />
          </div>
          <NoticeBar :visible="noticeVisiable" @close="closeNoticeBar" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { deepClone } from '@smart/util-web';
import { getRoleTypeMenu } from "@/router/menu";
import { setUserToken } from "@/global/tokenManager";
import MenuBar from "./menuBar.vue";
import NoticeBar from "./noticeBar.vue";
import { $sp_unreadNoticeCount } from '@/api/notice';

function deepCheckMenuAuth(menuArray, userAuth) {
  return menuArray.filter((menuItem) => {
    if (!menuItem.children) {
      return userAuth.includes(menuItem.name);
    } else {
      const childrenList = deepCheckMenuAuth(menuItem.children, userAuth);
      if (childrenList.length === 0) return false;
      // eslint-disable-next-line no-param-reassign
      menuItem.children = childrenList;
    }
    return true;
  });
}

export default {
  name: "MainContainer",
  components: {
    MenuBar,
    NoticeBar,
  },
  data() {
    return {
      keepAliveCompList: [],
      menuList: [],
      currentActive: null,
      currentActiveItem: null,
      tabList: [],
      unreadCount: 0,
      noticeVisiable: false,
    };
  },
  created() {
    this.initMenu();
    this.initPage();
    this.tabList = JSON.parse(sessionStorage.getItem("topTage")) || [];
    this.getUnreadNoticeCount();
  },
  watch: {
    $route: {
      handler(route) {
        if (!route) return;
        if (route.meta.cache === undefined || route.meta.cache === true) {
          const name = route.name.replace(route.name[0],route.name[0].toLowerCase());
          if (this.keepAliveCompList.indexOf(name)==-1) {
            this.keepAliveCompList.push(name);
          }
          if ((name == 'goodsAuditList' || name == 'goodsAuditNewList') && !this.keepAliveCompList.includes('goodsAudit')) {
            this.keepAliveCompList.push('goodsAudit');
          }
          if ((name == 'salesObjectivesTotal' || name == 'salesObjectivesClassify' || name == 'salesObjectivesGoods') && !this.keepAliveCompList.includes('salesObjectives')) {
            this.keepAliveCompList.push('salesObjectives');
          }
          if ((name == 'salesman' || name == 'team') && !this.keepAliveCompList.includes('staffManagement')) {
            this.keepAliveCompList.push('staffManagement');
          }
        }
        this.initPage();
      },
    },
    tabList: {
      handler(newValue) {
        sessionStorage.setItem("topTage", JSON.stringify(newValue));
      },
    },
  },
  methods: {
    async initMenu() {
      if (process.env.NODE_ENV === 'development') {
        this.menuList = deepClone(getRoleTypeMenu());
      } else {
        this.menuList = deepCheckMenuAuth(deepClone(getRoleTypeMenu()), (localStorage.getItem('admin_login_user_auth') || '').split(','),this.menuList);
      }
    },
    initPage() {
      const nameList = this.$route.matched.map(item => item.name).slice(0, 2);
      nameList.forEach((item, index) => {
        if (index == 0) {
          this.currentActive = this.menuList.find(
            (subItme) => subItme.name == item
          );
        } else if (this.currentActive && this.currentActive.children) {
          this.currentActiveItem = this.currentActive.children.find(
            (subItme) => subItme.name == item
          );
        }
      });
    },
    toPage(tag) {
      this.$router.push({
        name: tag.name,
      });
    },
    handleCloseTab(tabItem) {
      const index = this.tabList.findIndex(
        (item) => item.name === tabItem.name
      );
      const activeIndex = this.keepAliveCompList.indexOf(tabItem.name);
      if (activeIndex > -1) {
        this.keepAliveCompList.splice(activeIndex, 1);
      }
      if (index > -1) {
        this.tabList.splice(index, 1);
      }
      // 如果关闭的页面是 当前页面
      if (this.$route.name === tabItem.name) {
        if (this.tabList[index]) {
          this.navigatePath(this.tabList[index]);
        } else if (this.tabList[index - 1]) {
          this.navigatePath(this.tabList[index - 1]);
        }
      }
    },
    checkedFirstMenu(menuItem) {
      if (!menuItem.children) {
        this.currentActive = null;
        this.navigatePath(menuItem);
        return;
      }
      this.currentActive = menuItem;
      
      //增加默认打开二级导航第一个页面
      this.jumpToPage(menuItem.children[0], 'init')
    },
    jumpToPage(secItem) {
      // //订单模块默认打开二级点击收起二级
      // if (this.currentActive.title === '订单') {
      //   this.menuList[2].name = mode === 'init'?'Order':'order'
      // }

      this.currentActiveItem = secItem;
      this.navigatePath(secItem);
    },
    handleCommand(command) {
      if (command === "logout") {
        this.$confirm("是否退出登录?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          setUserToken("");
          localStorage.removeItem('admin_login_user_auth');
          localStorage.removeItem('admin_login_user_button');
          sessionStorage.removeItem("topTage")
          this.$router.replace("/login");
          location.reload()
        });
      } else if (command === 'changePass') {
        this.$router.push("/configurationAdmin/changePassword");
      } else if (command === 'recycleBin') {
        this.$router.push("/goods/recycleBin");
      }
    },
    navigatePath(item) {
      const index = this.tabList.findIndex(
        (tabItem) => tabItem.name === item.name
      );
      if (index === -1) {
        this.tabList.push(item);
      }
      if (this.$route.name === item.name) {
        this.$router.replace({
          name: item.name,
          query: {
            t: Date.now(),
          },
        });
        return;
      }
      if (this.tabList.length > 7) {
        this.tabList.shift();
      }

      this.$router.push({
        name: item.name,
      });
    },
    closeNoticeBar() {
      this.noticeVisiable = false;
      this.getUnreadNoticeCount();
    },
    getUnreadNoticeCount() {
      $sp_unreadNoticeCount().then(res => {
        this.unreadCount = res;
      });
    },
  },
};
</script>
<style lang="scss">
@import "@/style/var.scss";
@media (min-width: 1400px) {
}

.tags-view-wrapper {
  overflow: auto;
}
/* 设置滚动条的样式 */
.tags-view-wrapper::-webkit-scrollbar {
  height: 6px;
  width: 12px;
}
/* 滚动槽 */
.tags-view-wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.tags-view-wrapper .tags-view-item {
  display: inline-block;
  position: relative;
  cursor: pointer;
  height: 28px;
  line-height: 28px;
  border: 1px solid #d8dce5;
  color: #999;
  background: #fff;
  padding: 0 8px;
  font-size: 12px;
  margin-left: 5px;
  /*margin-top: 4px;*/
  box-sizing: border-box;
}

.tags-view-wrapper .tags-view-item:last-of-type {
  margin-right: 15px;
}

.router-link-exact-active {
  color: $themeColor !important;
  border-color: $themeColor !important;
}

.tags-view-item {
  border-radius: 4px;
}

.main-position-secondary {
  width: 150px;
  height: 100%;
}
.main-container-hidden {
  height: 100vh;
  overflow-y: hidden;
}

.main-container {
  width: 100vw;
  height: 100vh;
  min-width: 1400px;
  background-color: #f8fafd;
  .main-container-first-menu {
    height: 100%;
    width: 96px;
    box-sizing: border-box;
    background-color: #171b1f;
    font-size: 14px;
    line-height: 62px;
    color: #8b8d8f;
    text-align: center;
    user-select: none;
    .main-container-first-menu-list {
      width: 96px;
      height: calc(100% - 67px);
      box-sizing: border-box;
      overflow-y: auto;
      > li {
        cursor: pointer;
        height: 62px;
        &:hover {
          background-color: #272d34;
        }
        > i {
          margin-right: 6px;
        }
      }
    }
    .main-container-first-menu-active {
      background-color: $themeColor !important;
      color: #fff !important;
    }
  }
  .main-container-logo {
    height: 62px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    > img {
      width: 68px;
      height: auto;
    }
  }
  .main-container-header {
    height: 62px;
    background-color: #fff;
    border-bottom: 1px solid #f5f5f5;
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
    .main-container-header-tab {
      .el-tag {
        margin-right: 10px;
      }
    }
    .main-container-header-right {
      user-select: none;
      .el-icon-message {
        font-size: 20px;
        cursor: pointer;
      }
    }
    .main-container-header-line {
      width: 1px;
      background-color: #ececec;
      margin: 0 24px;
      height: 15px;
    }
    .main-container-header-dropdown {
      margin-left: 40px;
    }
  }
  .main-container-secondary-menu {
    background-color: #fff;
    width: 150px;
    line-height: 62px;
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    user-select: none;

    .main-container-secondary-menu-item {
      color: #666666;
      text-align: center;
      font-size: 16px;
      color: #333333;
      padding-left: 24px;
      cursor: pointer;
    }
    .main-container-secondary-menu-active {
      font-weight: bold;
      background-color: #ebf3ff !important;
      color: $themeColor !important;
    }
  }
  .main-container-nav {
    width: 100%;
    height: 40px;
    background-color: #fff;
    // overflow-x: auto;
    box-sizing: border-box;
    padding: 0 20px;
    white-space: nowrap;
    position: relative;
    overflow: hidden;
    width: 100%;
    user-select: none;
    .el-icon-close {
      margin-left: 4px;
      &:hover {
        background-color: #efefef !important;
      }
    }
  }
  .main-container-right {
    height: 100%;
    flex: 1;
    width: calc(100% - 96px);
    .main-no-nav {
      width: 100%;
    }
  }
  .main-container-wrap {
    height: calc(100% - 62px);
    flex: 1;
    .main-container-wrap-right {
      width: calc(100% - 150px);
    }
  }

  .main-container-content {
    // width: calc(100% - 150px);
    box-sizing: border-box;
    padding: 20px;
    overflow: hidden;
    background-color: #f8fafd;
    .main-content-style {
      background: #ffffff;
      border-radius: 5px;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    }
  }
  .main-container-dropdown-list {
    min-width: 120px;
    text-align: center;
  }
}
</style>

