import { UPLOAD_DOMAIN } from "@/config";
import api from "./api";

export const $get_getOssProperties = (params) => api.postNoMessage("/sssk/erp/api/platform/biz/oss/getOssSignature", params);

export const $post_uploadRequest = (params) => api.postNoMessage(UPLOAD_DOMAIN, params);

// 发送登录验证码
export const $p_sendCaptchaSms = (params) => api.post("/sssk/cloud/api/user/biz/send", params);

// 发送验证码
export const $sp_sendValidateCode = (params) => api.postNoMessage("/sssk/erp/api/platform/biz/sms/sendValidateCode", params);

// 验证验证码
export const $sp_verifyCode = (params) => api.post("/sssk/erp/api/platform/biz/sms/verifyCode", params);

// 获取地区信息
export const $sg_AllArea = () => api.get("/api/common/getAllArea");
