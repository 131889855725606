
/**
 * 混合两种颜色
 * @param {String} c1 颜色1
 * @param {String} c2 颜色2
 * @param {Number} ratio 第一个颜色所占的比例 0-1 之间
 * @returns 混合后的十六进制颜色字符串
 */
export const colourMix = (c1, c2, ratio) => {
  ratio = Math.max(Math.min(Number(ratio), 1), 0)
  let r1 = parseInt(c1.substring(1, 3), 16)
  let g1 = parseInt(c1.substring(3, 5), 16)
  let b1 = parseInt(c1.substring(5, 7), 16)
  let r2 = parseInt(c2.substring(1, 3), 16)
  let g2 = parseInt(c2.substring(3, 5), 16)
  let b2 = parseInt(c2.substring(5, 7), 16)
  let r = Math.round(r1 * (1 - ratio) + r2 * ratio)
  let g = Math.round(g1 * (1 - ratio) + g2 * ratio)
  let b = Math.round(b1 * (1 - ratio) + b2 * ratio)
  r = ('0' + (r || 0).toString(16)).slice(-2)
  g = ('0' + (g || 0).toString(16)).slice(-2)
  b = ('0' + (b || 0).toString(16)).slice(-2)
  return '#' + r + g + b
}


// 十六进制转为RGB
export const hex2Rgb = (hex) => {
  var rgb = []; // 定义rgb数组
  if (/^\#[0-9A-F]{3}$/i.test(hex)) { //判断传入是否为#三位十六进制数
    let sixHex = '#';
    hex.replace(/[0-9A-F]/ig, function (kw) {
      sixHex += kw + kw; //把三位16进制数转化为六位
    });
    hex = sixHex; //保存回hex
  }
  if (/^#[0-9A-F]{6}$/i.test(hex)) { //判断传入是否为#六位十六进制数
    hex.replace(/[0-9A-F]{2}/ig, function (kw) {
      rgb.push(eval('0x' + kw)); //十六进制转化为十进制并存如数组
    });
    return `rgb(${rgb.join(',')})`; //输出RGB格式颜色
  } else {
    console.log(`Input ${hex} is wrong!`);
    return 'rgb(0,0,0)';
  }
}

// RGB转为十六进制
export const rgb2Hex = (rgb) => {
  if (/^rgb\((\d{1,3}\,){2}\d{1,3}\)$/i.test(rgb)) { //test RGB
    var hex = '#'; //定义十六进制颜色变量
    rgb.replace(/\d{1,3}/g, function (kw) { //提取rgb数字
      kw = parseInt(kw).toString(16); //转为十六进制
      kw = kw.length < 2 ? 0 + kw : kw; //判断位数，保证两位
      hex += kw; //拼接
    });
    return hex; //返回十六进制
  } else {
    console.log(`Input ${rgb} is wrong!`);
    return '#000'; //输入格式错误,返回#000
  }
}

// 随机生成十六进制颜色
export const randomHexColor = () => {
  return '#' + ('00000' + (Math.random() * 0x1000000 << 0).toString(16)).substr(-6);
}

// 随机生成RGB颜色
export const randomRgbColor = () => {
  var r = Math.floor(Math.random() * 256); //随机生成256以内r值
  var g = Math.floor(Math.random() * 256); //随机生成256以内g值
  var b = Math.floor(Math.random() * 256); //随机生成256以内b值
  return `rgb(${r},${g},${b})`; //返回rgb(r,g,b)格式颜色
}

// 随机生成RGBA颜色
export const randomRgbaColor = () => {
  var r = Math.floor(Math.random() * 256); //随机生成256以内r值
  var g = Math.floor(Math.random() * 256); //随机生成256以内g值
  var b = Math.floor(Math.random() * 256); //随机生成256以内b值
  var alpha = Math.random(); //随机生成1以内a值
  return `rgb(${r},${g},${b},${alpha})`; //返回rgba(r,g,b,a)格式颜色
}
