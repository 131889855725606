import {
  addUrlOptionsStr,
  formatDate, formatDateByDate,
  verifyDateInterval, deadTime, formatMoney,
  formatTimeRangeStr,
  getFormatAreaByApi,
  smsUtil,
  validator,
} from '@smart/util-web';
import { Message } from 'element-ui';
import Config from '@smart/config';
import { UPLOAD_DOMAIN, getBaseUrl } from '@/config';
import {
  $p_sendCaptchaSms, $sg_AllArea,
} from '@/api/common';
import numberInput from './numberInput';

// 外部图片
const imgPrefix = (path, defaultPath, options) => {
  if (!path && !defaultPath) {
    return '/admin/img/unset.png';
  }
  if (!path && defaultPath) {
    if (defaultPath.startsWith('data:')) {
      return defaultPath;
    }
    path = defaultPath; // eslint-disable-line
  }
  if (path.startsWith('/img')) {
    return path;
  }
  if (path.startsWith('http://') || path.startsWith('https://')) {
    // 如果图片是完整的图片， 或是项目中的静态图片，则直接返回路径
    return addUrlOptionsStr(path, options);
  }
  return addUrlOptionsStr(`${UPLOAD_DOMAIN}${path}`, options);
};
export const $showButton = (name)=>{
  let buttons=localStorage.getItem('admin_login_user_button'); 
  buttons=buttons?buttons.split(','):[]
  if(!name)return false
  if(buttons.length){
    if(buttons.includes(name)){
      return true
    }else{
      return false
    }
  }else{
    return false
  }
};
export const $hasAuthRoute = (routeName)=>{
  let auths=localStorage.getItem('admin_login_user_auth')
  auths=auths?auths.split(','):[]
  if(!routeName)return false
  if(auths.length){
    if(auths.includes(routeName)){
      return true
    }else{
      return false
    }
  }else{
    return false
  }
}
export default {
  install(Vue) {
    Vue.prototype.$Config = Config;
    
    Vue.prototype.$hasAuthRoute = $hasAuthRoute;
    Vue.prototype.$showButton = $showButton;
    Vue.prototype.$imgPrefix = imgPrefix;
    // 格式化日期
    Vue.prototype.$formatDate = formatDate;
    Vue.prototype.$formatDateByDate = formatDateByDate;
    Vue.filter('formatDate', formatDate);
    // 处理查询日期间隔
    Vue.prototype.$verifyDateInterval = verifyDateInterval;
    // 到期时间
    Vue.prototype.$deadTime = deadTime;
    Vue.filter('deadTime', deadTime);
    // 图片
    Vue.filter('imgPrefix', imgPrefix);
    Vue.prototype.$imgPrefix = imgPrefix;
    // 格式化金额
    Vue.prototype.$formatMoney = formatMoney;
    Vue.filter('formatMoney', formatMoney);
    // 获取基本路径
    Vue.prototype.$getBaseUrl = getBaseUrl;

    // eslint-disable-next-line
    Vue.prototype.$formatSendTimeDesc = function (order_content,is_predict) {
      const time = order_content.send_time;
      if (time === 0) {
        if (is_predict < 3) { return '尽快送达'; }
        if (is_predict === 3 || is_predict === 4) { return '已在店内'; }
      }
      if (order_content.send_time_interval) {
        return formatTimeRangeStr(order_content.send_time_interval);
      }
      return this.$formatDate(time, 'MM-dd HH:mm');
    };

    Vue.prototype.$validator = (value, args1, args2) => validator(value, args1, args2).catch((err) => {
      Message.error(err);
      return Promise.reject(err);
    });
    Vue.directive('number', numberInput)
  },
};

export const getFormatArea = () => getFormatAreaByApi($sg_AllArea);

export const sendTelCode = (phone, appCode) => {
  // 终端区分（shop：商户端 user：用户端）
  // 签名（手机号 + 终端区分 + salt）
  const params = smsUtil({
    appName: appCode,
    phone,
  });
  return $p_sendCaptchaSms(params);
};

export const getQrcodeSrc = (content, size = 200) => `https://api.91joylife.net/saas/cloud/user/qrcode/create?size=${size}&content=${encodeURIComponent(content)}`;
