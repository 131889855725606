const menuList = [
  {
    title: '首页',
    icon: '',
    name: 'Index',
  },
  {
    title: "商品",
    icon: "",
    name: "Goods",
    children: [
      {
        title: "商品档案",
        icon: "",
        name: "GoodsFiles",
      },
      {
        title: "商品审核",
        icon: "",
        name: "GoodsAudit",
      },
      {
        title: "商品分类",
        icon: "",
        name: "GoodsTypes",
      },
      {
        title: "辅助资料",
        icon: "",
        name: "SecondaryData",
      },
      {
        title: "商品反馈",
        icon: "",
        name: "GoodsDemand",
      },
      {
        title: "回收站",
        icon: "",
        name: "RecycleBin",
      },
      {
        title: "溯源检测",
        icon: "",
        name: "GoodsCheck",
      },      
    ],
  },
  {
    title: "菜谱",
    icon: "",
    name: "Cookbook",
    children: [
      {
        title: "菜谱下单",
        icon: "",
        name: "CookbookOrder",
      },
      {
        title: "套餐档案",
        icon: "",
        name: "CookbookFiles",
      },
      {
        title: "餐次设置",
        icon: "",
        name: "Mealtimes",
      },
    ]
  },
  {
    title: "押金筐",
    icon: "",
    name: "DepositBasket",
    children: [
      {
        title: "押金筐列表",
        icon: "",
        name: "BasketList",
      },
      {
        title: "借还管理",
        icon: "",
        name: "BorrowAndReturnManagement",
      },
      {
        title: "借还统计",
        icon: "",
        name: "BorrowAndReturnStatistical",
      },
    ],
  },
  {
    title: "订单",
    icon: "",
    name: "Order",
    children: [
      {
        title: "订单列表",
        icon: "",
        name: "OrderList",
      },
      {
        title: "订单汇总",
        icon: "",
        name: "OrderSummary",
      },
      {
        title: "退货退款",
        icon: "",
        name: "OrderRefund",
      },
      {
        title: "异常订单",
        icon: "",
        name: "OrderAbnormal",
      },
    ],
  },
  {
    title: "采购",
    icon: "",
    name: "Purchase",
    children: [
      {
        title: "采购订单",
        icon: "",
        name: "PurchaseOrder",
      },
      {
        title: "采购退回",
        icon: "",
        name: "PurchaseReturn",
      },
      {
        title: "采购历史",
        icon: "",
        name: "PurchaseHistory",
      },
      {
        title: "智能预采",
        icon: "",
        name: "SmartPurchase",
      },
      {
        title: "修改进价",
        icon: "",
        name: "ChangePurchasingCost",
      },
      {
        title: "供应商",
        icon: "",
        name: "Suppliers",
      },
      {
        title: "供应商标签",
        icon: "",
        name: "SuppliersLabel",
      },
      {
        title: "采购员",
        icon: "",
        name: "Purchaser",
      },
    ],
  },
  {
    title: "库房",
    icon: "",
    name: "Warehouse",
    children: [
      {
        title: "商品分拣",
        icon: "",
        name: "GoodsSorting",
      },
      {
        title: "发货出库",
        icon: "",
        name: "DeliverGoods",
      },
      {
        title: "入库管理",
        icon: "",
        name: "PutInStorage",
      },
      {
        title: "出库管理",
        icon: "",
        name: "WarehouseManagement",
      },
      {
        title: "现有库存",
        icon: "",
        name: "Inventory",
      },
      {
        title: "出入库",
        icon: "",
        name: "StockInAndStockOut",
      },
      {
        title: "盘点库存",
        icon: "",
        name: "StockTaking",
      },
      {
        title: "报损报溢",
        icon: "",
        name: "ReportedLossToOverflow",
      },
      {
        title: "商品调拨",
        icon: "",
        name: "GoodsTransfers",
      },
      {
        title: "分拣员",
        icon: "",
        name: "Sorter",
      },
      {
        title: "仓库档案",
        icon: "",
        name: "WarehouseInfo",
      },
      {
        title: "分拣阈值",
        icon: "",
        name: "PrintThreshold",
      },
    ],
  },
  {
    title: "配送",
    icon: "",
    name: "Distribution",
    children: [
      {
        title: "路线",
        icon: "",
        name: "DistributionRoute",
      },
      {
        title: "早配排线",
        icon: "",
        name: "LogisticsRowLine",
      },
      {
        title: '午配排线',
        icon: '',
        name: 'LunchLogisticsRowLine',
      },
      {
        title: "区域",
        icon: "",
        name: "Area",
      },
      {
        title: "司机",
        icon: "",
        name: "Driver",
      },
      {
        title: "配送地图",
        icon: "",
        name: "DistributionMap",
      },
      {
        title: "运费设置",
        icon: "",
        name: "FreightSet",
      },
      {
        title: "客户运费",
        icon: "",
        name: "ClientFreight",
      },
    ],
  },
  {
    title: "客户",
    icon: "",
    name: "Customer",
    children: [
      {
        title: "客户档案",
        icon: "",
        name: "CustomerFiles",
      },
      {
        title: "客户类型",
        icon: "",
        name: "CustomerType",
      },
      {
        title: "集团管理",
        icon: "",
        name: "GroupManagement",
      },
      {
        title: '客户标签',
        icon: '',
        name: 'CustomerLabel',
      },
      {
        title: "客户积分",
        icon: "",
        name: "CustomerIntegral",
      },
      {
        title: "协议价",
        icon: "",
        name: "AgreementPrice",
      },
      {
        title: "智能定价",
        icon: "",
        name: "IntelligentPrice",
      },
      {
        title: "订货历史",
        icon: "",
        name: "OrderHistory",
      },
      {
        title: "下单情况",
        icon: "",
        name: "OrderSituation",
      },
      {
        title: "收藏夹",
        icon: "",
        name: "Favorites",
      },
      {
        title: "搜索记录",
        icon: "",
        name: "SearchRecords",
      },
    ],
  },
  {
    title: "营销",
    icon: "",
    name: "Marketing",
    children: [
      {
        title: "优惠券",
        icon: "",
        name: "Coupons",
      },
      {
        title: "限时抢购",
        icon: "",
        name: "FlashSale",
      },
      {
        title: "满减满赠",
        icon: "",
        name: "FullReduceFullGift",
      },
      {
        title: "积分活动",
        icon: "",
        name: "IntegralActivity",
      },
      {
        title: "商品限购",
        icon: "",
        name: "GoodsLimitation",
      },
      {
        title: "商品预售",
        icon: "",
        name: "GoodsPresell",
      },
    ],
  },
  {
    title: "报表",
    icon: "",
    name: "ReportForms",
    children: [
      {
        title: "营业数据",
        icon: "",
        name: "BusinessData",
      },
      {
        title: "商品销量",
        icon: "",
        name: "GoodsSales",
      },
      {
        title: "采购汇总",
        icon: "",
        name: "PurchaseSummary",
      },
      {
        title: "价格波动",
        icon: "",
        name: "PriceTrends",
      },
      {
        title: "销售毛利",
        icon: "",
        name: "SalesProfit",
      },
      {
        title: "流失数据",
        icon: "",
        name: "LoseData",
      },
      {
        title: "销售目标",
        icon: "",
        name: "SalesObjectives",
      },
      {
        title: "采购目标",
        icon: "",
        name: "PurchaseObjectives",
      },
    ],
  },
  {
    title: "配置",
    icon: "",
    name: "ConfigurationAdmin",
    children: [
      {
        title: "企业信息",
        icon: "",
        name: "EnterpriseInformation",
      },
      {
        title: "系统参数",
        icon: "",
        name: "SystemParameter",
      },
      {
        title: "支付方式",
        icon: "",
        name: "PayType",
      },
      {
        title: "应用地址",
        icon: "",
        name: "ApplicationAddress",
      },
      // {
      //   title: "版本管理",
      //   icon: "",
      //   name: "MpManagement",
      // },
      {
        title: "操作日志",
        icon: "",
        name: "OperationLog",
      },
      {
        title: "操作员",
        icon: "",
        name: "Operator",
      },
      {
        title: "角色管理",
        icon: "",
        name: "RoleManagement",
      },
      {
        title: "打印模版",
        icon: "",
        name: "PrintTemplate",
      },
      {
        title: "打印配置",
        icon: "",
        name: "PlotConfiguration",
      },
      {
        title: "分拣框",
        icon: "",
        name: "SortingBox",
      },
    ],
  },
  {
    title: "财务",
    icon: "",
    name: "Finance",
    children: [
      {
        title: "客户结算",
        icon: "",
        name: "CustomersSettlement",
      },
      {
        title: "客户结算单",
        icon: "",
        name: "FinalStatement",
      },
      {
        title: "客户余额",
        icon: "",
        name: "CustomerBalance",
      },
      // {
      //   title: "采购对账",
      //   icon: "",
      //   name: "ProcurementAccount",
      // },      
      {
        title: "采购结算",
        icon: "",
        name: "ProcurementSettlement",
      },
      {
        title: "批量对账",
        icon: "",
        name: "BatchReconciliation",
      },
      {
        title: "采购结算单",
        icon: "",
        name: "PurchaseStatement",
      },
      {
        title: "预付款余额",
        icon: "",
        name: "PrepayBalance",
      },      
      {
        title: "采购账款",
        icon: "",
        name: "PurchaseAccounts",
      },
      {
        title: "平台结算",
        icon: "",
        name: "PlatformSettlement",
      },
      {
        title: "结算",
        icon: "",
        name: "Settlement",
      },
      {
        title: "结算设置",
        icon: "",
        name: "SettlementSettings",
      },
      {
        title: "支付流水",
        icon: "",
        name: "PaymentDetails",
      },
      {
        title: "账期统计",
        icon: "",
        name: "AccountPeriodStatistics",
      },
    ],
  },
  {
    title: "CRM",
    icon: "",
    name: "Crm",
    children: [
      {
        title: "人员管理",
        icon: "",
        name: "StaffManagement",
      },
      {
        title: "业务绩效",
        icon: "",
        name: "BusinessPerformance",
      },
      {
        title: "拜访信息",
        icon: "",
        name: "VisitInfo",
      },
      {
        title: "任务管理",
        icon: "",
        name: "TaskManagement",
      },
      {
        title: "职级管理",
        icon: "",
        name: "LevelManagement",
      },
      {
        title: "提成设置",
        icon: "",
        name: "CommissionSetting",
      },
      {
        title: "绑定业务员",
        icon: "",
        name: "ClientSalesman",
      },
      {
        title: "提成统计",
        icon: "",
        name: "CommissionStatistics",
      },
      // {
      //   title: "调价限制",
      //   icon: "",
      //   name: "PriceAdjustmentRule",
      // },
      {
        title: "调价记录",
        icon: "",
        name: "PriceAdjustmentRecord",
      },
    ],
  },
  {
    title: "商城",
    icon: "",
    name: "Mall",
    children: [
      {
        title: "店铺",
        icon: "",
        name: "Store",
      },
      {
        title: "首页设置",
        icon: "",
        name: "IndexSetting",
      },
      {
        title: "首页弹窗",
        icon: "",
        name: "IndexAlert",
      },
      {
        title: "商城公告",
        icon: "",
        name: "MallAnnouncement",
      },
      {
        title: "服务条款",
        icon: "",
        name: "ServiceTerms",
      },
      {
        title: "关于我们",
        icon: "",
        name: "AboutUs",
      },
      {
        title: "售后规则",
        icon: "",
        name: "AfterRules",
      },
    ],
  },
  {
    title: "送货单",
    icon: "",
    name: "DeliveryNote",
    role: "supplier",
  },
  {
    title: "分拣打印",
    icon: "",
    name: "SortingPrint",
    role: "supplier",
  },
  {
    title: "商品",
    icon: "",
    name: "SupplierGoodsFiles",
    role: "supplier",
  },
  {
    title: "打印机设置",
    icon: "",
    name: "PrinterConfiguration",
    role: "supplier",
  },
];

export const getRoleTypeMenu = (isSupplier = false) => {
  return menuList.filter((item) => {
    if (isSupplier) return item.role === "supplier";
    else return !item.role;
  });
};

export const checkIsMenu = (name) => {
  return menuList.find((item) => {
    if (item.name === name) return true;
    if (item.children && item.children.length > 0) {
      return item.children.find((item) => item.name === name);
    }
    return false;
  });
};
