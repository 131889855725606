<template>
  <div>
    <template v-for="itemc in rowBtns.normalBtn">
      <el-button :key="itemc.id" :type="itemc.type||'text'" :disabled="itemc.disable" @click="$emit('handleClick',itemc.id)"
        v-if="itemc.show">
        {{itemc.label}}</el-button>
    </template>
    <template v-if="rowBtns.foldBtn && rowBtns.foldBtn.length">
      <el-dropdown @command="(val)=>$emit('handleClick',val.id)" style="margin-left:10px;">
        <span class="el-dropdown-link">
          更多<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="foldItem in rowBtns.foldBtn" :disabled="foldItem.disable" :command="foldItem" :key="foldItem.id">
            {{foldItem.label}}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    row: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    rowBtns() {
      const btnArr = this.getBtnList(this.row, this.item.btnList || []);
      const splitIndex = btnArr.length <= 4 ? 4 : 3;
      return {
        normalBtn: btnArr.slice(0, splitIndex),
        foldBtn: btnArr.slice(splitIndex),
      };
    },
  },
  methods: {
    // 获取按钮
    getBtnList(row, list) {
      const btnArr = list.map((item, index) => {
        let result = {};
        if (typeof item === 'object') {
          result = {
            ...item,
          };
        }
        return {
          ...result,
          id: this.getBtnId(row, item, index),
          label: this.getBtnLabel(item, row),
          show: this.getBtnIsShow(item, row),
          disable: this.getBtnIsDisable(item, row),
        };
      }).filter((itemc) => itemc.show);
      return btnArr;
    },
    // 获取按钮id
    getBtnId(row, item, index) {
      if (!item.id && !item.setId) return index;
      return item.id ? item.id : item.setId(row);
    },
    // 获取按钮文字
    getBtnLabel(item, row) {
      if (typeof item === 'string') return item;
      if (!item.label) return '';
      if (typeof item.label === 'string') return item.label;
      if (typeof item.label === 'function') return item.label(row);
      return '';
    },
    // 获取按钮显示状态
    getBtnIsShow(item, row) {
      if (typeof item.show === 'boolean') return item.show;
      if (typeof item.show === 'function') return item.show(row);
      return true;
    },
    // 获取按钮禁用状态
    getBtnIsDisable(item, row) {
      if (typeof item.disable === 'boolean') return item.disable;
      if (typeof item.disable === 'function') return item.disable(row);
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
.el-dropdown-link {
  cursor: pointer;
  color: $themeColor;
}
.el-icon-arrow-down {
  font-size: 12px;
}
</style>
