
/**
 * {
 *  accept: '', // 选择的文件类型
 *  multiple: false, // 是否多选
 *  directory: false, // 是否选择文件夹
 * }
 */
export default class ChooseFile {
  constructor(opt = {}) {
    this.opt = opt;
    this.fileInput = document.createElement('INPUT');
    this.fileInput.name = 'file';
    this.fileInput.id = `file-input-${~~(Math.random() * 1e6)}`;
    this.fileInput.type = 'file';
    this.fileInput.style.display = 'none';
    this.fileInput.setAttribute('accept', opt.accept || 'image/*');
    // multiple="multiple"
    if (opt.multiple) {
      this.fileInput.setAttribute('multiple', 'multiple');
    }
    if (opt.directory) {
      this.fileInput.setAttribute('webkitdirectory', true);
    }
    document.body.appendChild(this.fileInput);
    this.bindEvent();
  }

  bindEvent() {
    this.fileInput.addEventListener('change', (e) => {
      this.onFileChange(e);
      this.destroy();
    }, false);
  }

  destroy() {
    document.body.removeChild(this.fileInput);
  }

  chooseImage(callback) {
    this.chooseImageCallback = callback;
    const MouseEvents = document.createEvent('MouseEvents');
    MouseEvents.initEvent('click', true, true);
    this.fileInput.dispatchEvent(MouseEvents);
  }

  // 文件选择事件
  onFileChange(e) {
    const me = this;
    const file = e.target.files[0];
    if (file === undefined) {
      return;
    }
    if (this.opt.multiple) {
      me.chooseImageCallback(e.target.files);
    } else {
      const ext = e.target.value.split('.').pop();
      const fileInfo = {
        ext,
        value: me.fileInput.value,
        type: file.type,
        name: file.name,
        size: file.size,
      };
      me.chooseImageCallback(file, fileInfo);
    }
    // me.fileInput.files.length = 0;
    me.fileInput.value = '';
  }
}
