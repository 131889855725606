
import Vue from 'vue';
import { Message } from 'element-ui';
import ToolsUI from '@smart/smart-ui-vue';
import {
  requestCommonInterceptor,
  responseNeedLoginInterceptor,
  responseNeedBindTelInterceptor,
  responseDataInterceptor,
  loadAxiosInterceptors,
  FileUploader,
} from '@smart/util-web';
import router from '@/router';
import store from '@/store';
import { $get_getOssProperties, $post_uploadRequest } from '@/api/common';
import { getBaseUrl, UPLOAD_DOMAIN } from '@/config';
import {
  getUserToken,
  setUserToken
} from '@/global/tokenManager';

const clearLoginInfo=()=>{
  setUserToken("");
  localStorage.removeItem('admin_login_user_auth');
  localStorage.removeItem('admin_login_user_button');
  sessionStorage.removeItem("topTage")
}

loadAxiosInterceptors([requestCommonInterceptor], [
  responseNeedLoginInterceptor,
  responseNeedBindTelInterceptor,
  responseDataInterceptor,
], {
  router,
  store,
  messageSuccess: Message.success,
  messageError: Message.error,
  loading() {
    // if (loadingFlag) {
    //   Toast.loading();
    // }
  },
  toWxLogin() {
    clearLoginInfo()    
    router.push('/login');
  },
  toBindTel() {
    clearLoginInfo()  
    router.push('/login');
  },
  getUserToken,
  getBaseUrl,
  getParamsData() {
    return {};
  },
});

FileUploader.setFileUploadApis({
  getOssProperty: $get_getOssProperties,
  uploadRequest: $post_uploadRequest,
  // addPlatPicture: $sp_createPlatPicture,
  requestDomain: UPLOAD_DOMAIN,
  loadingFn(loadingFlag) {
    console.log(loadingFlag);
    // if (loadingFlag) {
    //   Toast.loading();
    // } else {
    //   Toast.clear();
    // }
  },
});

Vue.use(ToolsUI);

