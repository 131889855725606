import api from "./api";

// 未读公告条数
export const $sp_unreadNoticeCount = (params) => api.getNoTips("/sssk/erp/api/platform/biz/notice/unreadTotal", params);
// 公告列表
export const $sp_queryNoticeList = (params) => api.getNoTips("/sssk/erp/api/platform/biz/notice/getAll", params);
// 公告详情
export const $sp_queryNoticeInfo = (params) => api.getNoTips("/sssk/erp/api/platform/biz/notice/getInfo", params);
// 公告全部已读
export const $sp_readAllNotice = (params) => api.postNoTips("/sssk/erp/api/platform/biz/notice/readAll", params);
// 清空公告列表
export const $sp_clearAllNotice = (params) => api.post("/sssk/erp/api/platform/biz/notice/clean", params);
