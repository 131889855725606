import { render, staticRenderFns } from "./setTableCol.vue?vue&type=template&id=0e79f803&scoped=true&"
import script from "./setTableCol.vue?vue&type=script&lang=js&"
export * from "./setTableCol.vue?vue&type=script&lang=js&"
import style0 from "./setTableCol.vue?vue&type=style&index=0&id=0e79f803&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../web-project/admin/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e79f803",
  null
  
)

export default component.exports