import TableWidget from '../widget/TableWidget';

function parseTableField(columnList) {
  const parsedColumnsList = columnList.map((columnItem) => {
    if (typeof columnItem === 'string') {
      const [prop, label, width, align = 'center'] = columnItem.split('|');
      return new TableWidget({
        widget: 'text',
        prop,
        label,
        width,
        align,
        option: {},
      });
    }
    if (!columnItem.align) {
      // eslint-disable-next-line
      columnItem.align = 'center';
    }
    const tableColumn = new TableWidget(columnItem);
    return tableColumn;
  });
  return parsedColumnsList;
}

export default function (pageOptions) {
  return parseTableField(pageOptions.columns);
}
