
import loadScript from '../base/loadScript';

let WxSdkLoading = false;
let waitingWxSdkTimer;

export const loadWxJsSDK = () => new Promise((resolve) => {
  if (window.wx) {
    resolve(window.wx);
    return;
  }
  if (WxSdkLoading) {
    waitingWxSdkTimer = setInterval(() => {
      if (!WxSdkLoading) {
        clearInterval(waitingWxSdkTimer);
        resolve(window.wx);
      }
    }, 300);
    return;
  }
  WxSdkLoading = true;
  loadScript('/static/weixin/jweixin-1.6.0.js').then(() => {
    WxSdkLoading = false;
    resolve(window.wx);
  });
});

let loadQQGeolocationLoading = false;
let loadQQGeolocationTimer;
export const loadQQGeolocation = () => new Promise((resolve) => {
  if (window.qq) {
    resolve(window.qq);
    return;
  }
  if (loadQQGeolocationLoading) {
    loadQQGeolocationTimer = setInterval(() => {
      if (!loadQQGeolocationLoading) {
        clearInterval(loadQQGeolocationTimer);
        resolve(window.qq);
      }
    }, 300);
    return;
  }
  loadQQGeolocationLoading = true;
  loadScript('https://3gimg.qq.com/lightmap/components/geolocation/geolocation.min.js').then(() => {
    loadQQGeolocationLoading = false;
    resolve(window.qq);
  });
});

let AmpaLoading = false;
let waitingAmapTimer;
function aMapScriptLoading() {
  AmpaLoading = true;
  const aMapKey = localStorage.getItem('amap_key')?localStorage.getItem('amap_key'):'f93665d20d53ca0c0d6ac3894776e5be'
  window._AMapSecurityConfig = {
      securityJsCode: localStorage.getItem('amap_JsCode')?localStorage.getItem('amap_JsCode'):'ff523af6b1502f8ae4092a563c2d066c',
  }
  return loadScript('https://webapi.amap.com/js/marker.js')
    .then(() => loadScript(`https://webapi.amap.com/maps?v=1.4.15&key=${aMapKey}&plugin=AMap.PolyEditor,AMap.LabelMarker,AMap.MarkerClusterer,AMap.Walking,AMap.AdvancedInfoWindow,AMap.Transfer,AMap.Autocomplete,AMap.PlaceSearch,AMap.getPoints,AMap.Geocoder,AMap.Riding,AMap.Text,AMap.Driving,AMap.GeometryUtil,AMap.MouseTool,AMap.CitySearch`))
    .then(() => loadScript('https://cache.amap.com/lbs/static/TransferRender1230.js'))
    .then(() => loadScript('https://webapi.amap.com/ui/1.0/main.js?v=1.0.11'))
    .then(() => { AmpaLoading = false; })
    .catch(() => { AmpaLoading = false; });
}

export const loadAMap = () => new Promise((resolve) => {
  if (window.AMap) {
    resolve(window.AMap);
    return;
  }
  if (AmpaLoading) {
    waitingAmapTimer = setInterval(() => {
      if (!AmpaLoading) {
        clearInterval(waitingAmapTimer);
        resolve(window.AMap);
      }
    }, 300);
    return;
  }
  aMapScriptLoading().then(() => {
    setTimeout(() => {
      resolve(window.AMap);
    }, 600);
  });
});


// 异步加载tinymce富文本
let tinymceLoading = false;
let tinymceTimerid;
function tinymceScriptLoading(type) {
  tinymceLoading = true;
  return loadScript(`/${type}/tinymce/js/tinymce/tinymce.min.js`).then(() => {
    tinymceLoading = false;
  });
}
export const loadTEditor = (type = 'admin') => new Promise((resolve) => {
  if (window.tinymce) {
    resolve(window.tinymce);
    return;
  }
  if (tinymceLoading) {
    tinymceTimerid = setInterval(() => {
      if (!tinymceLoading) {
        clearInterval(tinymceTimerid);
        resolve(window.tinymce);
      }
    }, 300);
    return;
  }
  tinymceScriptLoading(type).then(() => {
    setTimeout(() => {
      resolve(window.tinymce);
    }, 600);
  });
});
