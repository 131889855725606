import {
  axiosInstance,
  CustomConfigEnum,
} from '@smart/util-web';

// function getRandomInRange(min, max) {
//   return Math.round(Math.random() * (max - min)) + min;
// }

// axiosInstance.defaults.headers.common.tags = 'dev';
export const axiosGet = (url, params, config) => axiosInstance.get(url, {
  params,
  ...config,
});

export const axiosPost = (url, params, config) => axiosInstance.post(url, params, config);

export default {

  // 三餐ajax
  get: (url, params, config) => axiosGet(url, params, {
    ...config,
    customConfig: CustomConfigEnum.DATA_REJECT | CustomConfigEnum.SHOW_LOADING | CustomConfigEnum.SHOW_MESSAGE,
  }),
  post: (url, params, config) => axiosPost(url, params, {
    ...config,
    customConfig: CustomConfigEnum.DATA_REJECT | CustomConfigEnum.SHOW_LOADING | CustomConfigEnum.SHOW_MESSAGE,
  }),

  // 不提示MESSAGE
  getNoMessage: (url, params, config = {}) => axiosGet(url, params, {
    ...config,
    customConfig: CustomConfigEnum.DATA_REJECT | CustomConfigEnum.SHOW_LOADING,
  }),
  postNoMessage: (url, params, config = {}) => axiosPost(url, params, {
    ...config,
    customConfig: CustomConfigEnum.DATA_REJECT | CustomConfigEnum.SHOW_LOADING,
  }),

  // 不提示MESSAGE, 不loading
  getNoTips: (url, params, config = {}) => axiosGet(url, params, {
    ...config,
    customConfig: CustomConfigEnum.DATA_REJECT,
  }),
  postNoTips: (url, params, config = {}) => axiosPost(url, params, {
    ...config,
    customConfig: CustomConfigEnum.DATA_REJECT,
  }),

  // 不Reject , 返回完整resp,
  getNoReject: (url, params, config = {}) => axiosGet(url, params, {
    ...config,
    customConfig: CustomConfigEnum.SHOW_LOADING | CustomConfigEnum.SHOW_MESSAGE,
  }),
  postNoReject: (url, params, config = {}) => axiosPost(url, params, {
    ...config,
    customConfig: CustomConfigEnum.SHOW_LOADING | CustomConfigEnum.SHOW_MESSAGE,
  }),

  // 不Reject , 返回完整resp 不loading 不 message,
  getNoRejectNoTips: (url, params, config = {}) => axiosGet(url, params, config),
  delete: (url, params, config = {}) => axiosInstance.delete(url, {
    params,
    ...config,
  }),
  postNoRejectNoTips: (url, params, config = {}) => axiosPost(url, params, config),
};
