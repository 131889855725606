<template>
  <div class="table-empty-comp">
    <img class="empty-img" src="/admin/img/common/hold_image.png">
    <p>暂时还没有数据！！！</p>
  </div>
</template>
<script>
export default {
  name: 'TableEmpty',
};
</script>
<style lang="scss">
.table-empty-comp {
  padding-top: 35px;
  .empty-img {
    width: 160px;
  }
}
</style>
