import { loadQQGeolocation, loadAMap } from './loadSdkScript';

export function getLocationByQQMap(errorMsg) {
  return loadQQGeolocation().then(() => new Promise((resolve, reject) => {
    // eslint-disable-next-line
    if (window.__wxjs_environment === 'miniprogram') {
      console.log('小程序中不使用腾讯地图定位');
      reject(false);
      return;
    }
    const geolocation = new qq.maps.Geolocation('OB4BZ-D4W3U-B7VVO-4PJWW-6TKDJ-WPB77', 'myapp');
    if (geolocation) {
      geolocation.getLocation((mapPosi) => {
        const position = {
          address: mapPosi.formattedAddress,
          lng: mapPosi.lng,
          lat: mapPosi.lat,
        };
        console.log('腾讯地图定位成功');
        resolve({
          ...mapPosi,
          position,
        });
      }, () => {
        console.log('腾讯地图定位失败');
        reject(errorMsg);
      }, { timeout: 8000 });
    }
  }));
}

const AMapGetLocationConfig = {
  enableHighAccuracy: true,
  timeout: 10000,
  zoomToAccuracy: true,
};
export function getLocationByAMap(defErrMsg) {
  return new Promise((resolve, reject) => {
    AMap.plugin('AMap.Geolocation', () => {
      const geolocation = new AMap.Geolocation(AMapGetLocationConfig);
      geolocation.getCurrentPosition();
      AMap.event.addListener(geolocation, 'complete', (data) => {
        console.log('高德地图定位成功');
        resolve(data);
      });
      AMap.event.addListener(geolocation, 'error', (err) => {
        console.log('geolocation-err',err)
        let errorMag = '';
        if (err.info === 'NOT_SUPPORTED') {
          errorMag = '当前浏览器不支持定位功能!';
        } else if (err.info === 'FAILED') {
          if (err.message.indexOf('time out') !== -1) {
            errorMag = '定位超时!';
          } else if (err.message.indexOf('permission') !== -1) {
            errorMag = '定位权限异常，请开启位置授权!';
          } else {
            errorMag = '定位失败!';
          }
        } else {
          errorMag = '定位失败!';
        }
        console.log('高德地图定位失败');
        console.log(errorMag || defErrMsg);
        reject(errorMag || defErrMsg);
      });
    });
  });
}

function getLocationByWxSDK(defErrMsg) {
  return new Promise((resolve, reject) => {
    const { userAgent } = navigator;
    if (!userAgent.includes('MicroMessenger')) {
      console.log('不是微信公众号');
      // eslint-disable-next-line
      reject(defErrMsg);
      return;
    }
    console.log('是微信公众号');
    const getlocstionFun = () => {
      window.wx.getLocation({
        type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
        success(res) {
          console.log('微信SDK定位成功', res);
          // var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
          // var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
          // var speed = res.speed; // 速度，以米/每秒计
          // var accuracy = res.accuracy; // 位置精度
          const local = {
            // ...res,
            lng: res.longitude,
            type: 'gcj02',
            lat: res.latitude,
          };
          resolve(local);
        },
        cancel() {
          console.log('sdk error');
          reject(defErrMsg);
        },
        fail(error) {
          console.log('微信SDK定位失败', error);
          reject(defErrMsg);
        },
      });
    };
    const interValTimer = setInterval(() => {
      console.log('doing', window.wxConfigState);
      if (window.wxConfigState !== 'doing') {
        clearInterval(interValTimer);
        if (window.wxConfigState === 'success') {
          getlocstionFun();
        } else {
          reject(defErrMsg);
        }
      }
    }, 100);
  });
}

/* 获取单点标记的位置文本信息 */
export const getLocationDetails = (lng, lat) => { // eslint-disable-line
  return new Promise((resolve, reject) => {
    if (!AMap) {
      reject();
      return;
    }
    AMap.plugin('AMap.Geocoder', () => {
      const geocoder = new AMap.Geocoder();
      geocoder.getAddress([lng, lat], (status, result) => {
        console.log('dddddddddddddddddddd', status, result);
        if (status === 'complete' && result.info === 'OK') {
          // ads = result.regeocode.formattedAddress;
          resolve(result.regeocode);
        } else {
          reject(result);
        }
      });
    });
  });
};

/* address: position.address || position.formattedAddress,
formattedAddress: position.formattedAddress,
  point: getPointByPosition(position),
    location: {
            ...position.location,
          },
position: {
            ...position.position,
          },
*/
// lng lat
export function getUserCurrentAddress() {
  let positions = localStorage.getItem('user_wap_local_init_position');
  if (positions) {
    try {
      positions = JSON.parse(positions);
    } catch (e) {
      console.error('get weishi Location str Error', positions);
    }
    if (positions && positions.longitude && positions.latitude && (positions.address || positions.F_Addressc)) {
      return Promise.resolve({
        formattedAddress: positions.address || positions.F_Addressc,
        address: positions.address || positions.F_Addressc,
        lng: positions.longitude,
        lat: positions.latitude,
      });
    }
  }

  // fundebug.notify("get weishi Location Error", new Error());
  return getLocationByWxSDK()
    // .catch((wxerr) => loadAMap().then(() => getLocationByAMap(wxerr)).catch((err) => getLocationByQQMap(err)))
    .then((data) => {
      if (!data.address) {
        // 如果是公众号或其他的定位信息结果会没有地址描述信息， 所以此处需要根据ip从新获取一次地址描述信息
        return loadAMap().then(() => {
          console.log([data.lng, data.lat], '[data.lng, data.lat]');
          return getLocationDetails(data.lng, data.lat);
        }).then(({ formattedAddress }) => {
          // eslint-disable-next-line
          data.address = formattedAddress;
          return data;
        }).catch((error) => {
          console.error(error, 'sdfasdfasdf');
          return Promise.reject(error);
        });
      }
      return data;
    });
}
