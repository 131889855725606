<template>
  <div class="errorPage">
    <div class="notice">
      <img class="notice-img"
        :src="$imgPrefix(`/public/joint_food/admin/error-page-${hasPageAuth?'404':'nodata'}.png`)"
      />
      <div class="notice-text">
        <span>{{hasPageAuth?'您暂无权限访问此页面或页面地址不正确,请联系管理员～～':'您暂无可访问页面数据，请联系管理员～～'}}</span>
        <el-button type="text" @click="backPrev">返回上页</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { setUserToken } from "@/global/tokenManager";
export default {
  name: "ErrorPage",
  data() {
    return {
      hasPageAuth:false,
    };
  },  
  mounted() {    
    if(window.history && window.history.pushState){
      window.addEventListener('popstate',this.backChange,false)
    }     
    let pageList = localStorage.getItem('admin_login_user_auth');
    if(pageList){
      this.hasPageAuth=true 
    }else{     
      this.hasPageAuth=false //无页面访问权限
    }
  },
  methods:{
		backChange(){
			this.restUserInfo()    
		},    
    restUserInfo(){
      if(!this.hasPageAuth){
        setUserToken("");
        localStorage.removeItem('admin_login_user_auth');
        localStorage.removeItem('admin_login_user_button');     
        sessionStorage.removeItem("topTage")   
      }
    },
    backPrev(){
      if(this.hasPageAuth){
        this.$router.go(-1)
      }else{
        this.restUserInfo()  
        this.$router.replace("/login");  
        location.reload()
      }
    },
    destroyed(){
      //销毁监听
      window.removeEventListener('popstate',this.backChange,false)
    }        
  }
}
</script>

<style scoped lang="scss">
.errorPage{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  .notice{
    .notice-img{
      width: 496px;
      height: 300px;
    }
    .notice-text{
      font-size: 16px;
      color: #666666;
    }
  }
}
</style>