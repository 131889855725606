<template>
  <div class="setting-header">
    <p class="setting-header-title"><span>表格表头设置</span><i class="el-icon-close" @click="closeMenuPopover"></i></p>
    <div class="setting-header-main">
      <p class="setting-header-inner-label">
        <span class="left-label">可选表头</span>
        <span class="right-babel">当前显示表头</span>
      </p>
      <div class="setting-content">
        <div class="content-left">
          <el-checkbox :label="item.option.label" v-model="item.show" :disabled="item.disable" v-for="item in columns" :key="item.key">
          </el-checkbox>
        </div>
        <div class="content-right">
          <ul class="choosed-table-columns">
            <draggable v-model="choosedColumns" filter=".draggableDisabled" animation="300" :move="onMove">
              <transition-group>
                <li v-for="item in choosedColumns" :class="item.disable?'draggableDisabled':'move'" :key="item.id">
                  <span>{{item.option.label}}</span><i class="el-icon-close" v-if="!item.disable"
                    @click="deleteChoosedColumns(item.key)"></i>
                </li>
              </transition-group>
            </draggable>
          </ul>
        </div>
      </div>
      <p class="setting-footer-btn">
        <el-button @click="closeMenuPopover">取消</el-button>
        <el-button type="primary" @click="submitChoosedMenu">确定</el-button>
      </p>
    </div>
  </div>
</template>

<script>
import Draggable from 'vuedraggable';
import { cloneDeep } from '@smart/util-base';

export default {
  components: {
    Draggable,
  },
  props: {
    // 所有参与表头设置的列
    columnsStatus: {
      type: Array,
      default: () => ([]),
    },
    position: {
      type: Object,
      default: () => ({
        top: '50px',
        left: '6px',
      }),
    },
    // 默认显示的列
    defaultShowCol: {
      type: Array,
      default: () => ([]),
    },
    // 当前表格配置存储key
    saveKey: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      columns: [],
      choosedColumns: [],
    };
  },
  watch: {
    // 监听所有可参与配置表头的列 重新赋值给columns
    columnsStatus: {
      handler(val) {
        this.columns = cloneDeep(val);
      },
      immediate: true,
      deep: true,
    },
    // 监听配置表头所有列的显示隐藏状态 动态计算当前显示的列
    columns: {
      handler(val) {
        this.choosedColumns = val.filter((item) => item.show);
      },
      immediate: true,
      deep: true,
    },
    // 监听默认配置列 设置选中列
    defaultShowCol: {
      handler(val) {
        this.choosedColumns = cloneDeep(val);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    // 禁止拖动到禁用元素位置
    onMove(e) {
      if (e.relatedContext.element.disable) return false;
      return true;
    },
    // 关闭表头设置
    closeMenuPopover() {
      this.$emit('closeMenuPopover');
    },
    // 保存当前表头设置内容
    submitChoosedMenu() {
      const choosedColumnsKey = this.choosedColumns.map((item) => {
        if (item.disable) {
          return `*${item.key}`;
        }
        return item.key;
      });
      localStorage.setItem(this.saveKey, JSON.stringify(choosedColumnsKey));
      this.$emit('submitChoosedMenu', this.choosedColumns);
    },
    // 删除当前显示列中某一列
    deleteChoosedColumns(key) {
      const resultIndex = this.columns.findIndex((item) => item.key === key);
      if (resultIndex > -1) {
        this.columns[resultIndex].show = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.setting-header {
  position: absolute;
  width: 800px;
  z-index: 10000;
  background: #fff;
  border: 1px solid #ebeef5;
  top: 50px;
  left: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  .setting-header-title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;
    padding: 0 26px 0 34px;
    &::before {
      position: absolute;
      left: 26px;
      content: "";
      display: inline-block;
      width: 4px;
      height: 14px;
      border-radius: 2px;
      background: $themeColor;
    }
  }
  .setting-header-inner-label {
    display: flex;
    justify-content: space-between;
    background: rgba(246, 248, 249, 0.5);
    border: 0.5px solid #e8e8e8;
    border-right: none;
    border-left: none;
    color: #505050;
    text-align: left;
    .left-label,
    .right-babel {
      display: inline-block;
      height: 36px;
      line-height: 36px;
      box-sizing: border-box;
      padding-left: 26px;
    }
    .left-label {
      width: calc(70% - 0.5px);
      border-right: 0.5px solid #e8e8e8;
    }
    .right-babel {
      width: 30%;
    }
  }
  .setting-content {
    display: flex;
    height: 290px;
    border-bottom: 0.5px solid #e8e8e8;
    .content-left {
      width: calc(70% - 0.5px);
      height: 100%;
      border-right: 0.5px solid #e8e8e8;
      border-top: none;
      border-left: none;
      box-sizing: border-box;
      overflow: auto;
      padding: 10px 26px 0 26px;
      .el-checkbox {
        margin-bottom: 10px;
      }
    }
    .content-right {
      width: 30%;
      height: 100%;
      overflow: auto;
      box-sizing: border-box;
      padding-top: 10px;
      .choosed-table-columns {
        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 32px;
          box-sizing: border-box;
          padding: 0 26px;
          cursor: pointer;
          &:hover {
            background: #e6f7ec;
          }
        }
        .draggableDisabled {
          cursor: not-allowed;
        }
        .move {
          cursor: move;
        }
      }
    }
  }
  .setting-footer-btn {
    text-align: right;
    box-sizing: border-box;
    padding: 14px 26px;
  }
  .el-icon-close {
    box-sizing: border-box;
    padding: 5px;
    cursor: pointer;
  }
}
</style>
