import api from "./api";

// 任务列表
export const $sg_userTaskQuery = (params) =>
  api.getNoTips(
    localStorage.getItem("admin_login_user_type") == 4
      ? "/sssk/erp/api/supplier/biz/common/queryTaskList"
      : "/sssk/erp/api/platform/biz/usrTask/query",
    params
  );
// 清空任务
export const $sp_clearUserTask = (params) =>
  api.post(
    localStorage.getItem("admin_login_user_type") == 4
      ? "/sssk/erp/api/supplier/biz/common/taskClear"
      : "/sssk/erp/api/platform/biz/usrTask/clear",
    params
  );


  //任务列表-更新是否下载状态
  export const $sp_updateDownloadStatus = (params) => api.postNoMessage('/sssk/erp/api/platform/biz/usrTask/download', params);