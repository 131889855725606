import { deepClone } from '@smart/util-base';
import binaryStore from './lib/binary';
import agentConfig from './lib/agent';
import runningConfig from './lib/running'


const configStore = {
  ...agentConfig,
  ...runningConfig,
};



function parseConfigStrToObj(str, options) {
  return str.split('&').map((item) => {
    const temp = item.split(':');
    const code = parseInt(temp[0], 10);
    return {
      [options.value]: isNaN(code) ? temp[0] : code,
      [options.label]: temp[1],
    };
  });
}


export class Config {

  constructor(flag, options = { value: 'value', label: 'label', flag: 'flag' }) {
    if (!flag) throw new Error('配置名称必填');
    this.options = options;
    const configItem = configStore[flag];
    if (typeof configItem === 'string') {
      this.value = parseConfigStrToObj(configItem, options);
    } else if (configItem !== undefined) {
      this.value =  deepClone(configItem);
    } else {
      this.value = configItem;
    }
  }

  getValue() {
    return this.value;
  }

  getItem(value) {
    if (this.value === undefined) return '';
    const configItem = this.value.find((item) => item[this.options.value] === value);
    return configItem;
  }

  getItemByIndex(index) {
    return this.value[index];
  }

  getItemByFlag(flag) {
    if (this.value === undefined) return '';
    const configItem = this.value.find((item) => item[this.options.flag] === flag);
    return configItem;
  }

  getLabel(value) {
    const configItem = this.getItem(value);
    if (configItem === undefined) return '';
    return configItem[this.options.label];
  }
}

export default Config;

export class BinaryConfig {


  constructor(flag, options = { value: 'value', label: 'label' }) {
    if (!flag) throw new Error('配置名称必填');
    const configItem = binaryStore[flag];
    if (configItem === undefined) throw new Error('该配置项不存在，请检查名称是否正确');
    this.options = options;
    const value = configItem.map((item) => ({
      [options.value]: item.value,
      [options.label]: item.label,
      flag: item.flag,
    }));
    this.value = value;
  }


  // 获取有开关变量的配置值
  getValue(value = 0, switchName = 'open') {
    return this.value.map(item => ({
      ...item,
      [switchName]: this.isOpen(value, item[this.options.value])
    }));
  }


  // 传入选择的 选项值数组 返回二进制与的结果值
  getSelectFinalValue(values = []) {
    return values.reduce((total, value) => (total | value))
  }

  // 检查一个配置值是否开启
  // 如 isOpen(7, 1) // true
  isOpen(value, flag) {
    return (value & flag) > 0;
  }
}
