import BaseWidget from './BaseWidget';

export default class FormWidget extends BaseWidget {
  widget = 'input'

  label = '';

  placeholder = '';

  slotName = ''

  slotLabel = ''

  optionsData = [];


  constructor(widget, key, id, label, placeholder = '', customProps = {}, slotName, slotLabel, span = 6, option, hidden = false, disable = false, handle) {
    super(key, id);
    this.widget = widget;
    this.label = label;
    this.placeholder = placeholder;
    this.options = customProps;
    this.slotName = slotName;
    this.slotLabel = slotLabel;
    this.span = span;
    this.hidden = hidden;
    this.disable = disable;
    this.handle = handle;
    if (option) {
      console.error('option 此属性已经废弃， 请使用customProps 替代', this);
    }
  }
}
