/**
 *
 * @describe 工具方法集锦
 * @author ZhangHong
 * @date 2018/6/6 11:07
 */
// 判断值是不是null
//  return value === undefined || value === null vue源码判空,这样更简练些
const isNull = (value) => {
  if (value === null || value === undefined) return true;
  return false;
};

// 判断变量是否是null 和空字符串
const isEmpty = (value) => {
  if (isNull(value)) return true;
  if (value === '') return true;
  return false;
};

// 判断对象的数据类型 Null Undefined Object Array [Object // 8
const isClass = (o) => {
  if (o === null) return 'Null';
  if (o === undefined) return 'Undefined';
  return Object.prototype.toString.call(o).slice(8, -1);
};

// 判断是否是日期
const isDate = (date) => {
  if (isNull(date)) return false;
  return date instanceof Date;
};

const isFunction = (obj) => (typeof obj === 'function' && typeof obj.nodeType !== 'number');

// 深度拷贝一个对象
const deepClone = (obj) => {
  let result;
  const oClass = isClass(obj);
  if (oClass === 'Object') {
    result = {};
  } else if (oClass === 'Array') {
    result = [];
  } else {
    return obj;
  }
  for (const key in obj) { // eslint-disable-line
    const copy = obj[key];
    if (isClass(copy) === 'Object') {
      result[key] = deepClone(copy);// 递归调用
    } else if (isClass(copy) === 'Array') {
      result[key] = deepClone(copy);
    } else {
      result[key] = obj[key];
    }
  }
  return result;
};


const replaceStrVar = (url, row) => {
  if (!row) return url;
  const reg = /{\w*}/mg;
  const newStr = url.replace(reg, (a) => {
    const tpl = a.slice(1, -1);
    if (row[tpl]) {
      return row[tpl];
    }
    return a;
  });
  return newStr;
};

// 通过A标签去解析url
const parseURL = (url) => {
  const a = document.createElement('a');
  a.href = url;
  return {
    source: url,
    protocol: a.protocol.replace(':', ''),
    host: a.hostname,
    port: a.port || '80',
    query: a.search,
    params: (() => {
      const ret = {};
      const seg = a.search.replace(/^\?/, '').split('&');
      const len = seg.length;
      let i = 0;
      let s;
      for (; i < len; i += 1) {
        if (seg[i]) {
          s = seg[i].split('=');
          ret[s[0]] = s[1];
        }
      }
      return ret;
    })(),
    file: (a.pathname.match(/\/([^\/?#]+)$/i) || [, ''])[1],// eslint-disable-line
    hash: a.hash.replace('#', ''),
    path: a.pathname.replace(/^([^\/])/, '/$1'),// eslint-disable-line
    relative: (a.href.match(/tps?:\/\/[^\/]+(.+)/) || [, ''])[1],// eslint-disable-line
    segments: a.pathname.replace(/^\//, '').split('/'),
  };
};
/**
 * 生成随机数
 * @returns {string}
 */
const random = () => {
  let num = new Date().getTime().toString().substring(4);
  num += (Math.random() * 9999).toFixed(0);
  return num;
};

// 搜索
const searchList = (str, list, key) => {
  let shopList = [];
  if (str === '') {
    shopList = list;
  } else {
    list.forEach((item) => {
      if (item[key] === str || item[key].indexOf(str) >= 0) {
        shopList.push(item);
      }
    });
  }
  return shopList;
};

const linkClearCache = (link) => {
  if (link.indexOf('#') !== -1) {
    const links = link.split('#');
    let newLink = links[0];
    if (newLink.indexOf('?') !== -1) {
      newLink += `&t=${new Date().getTime()}`;
    } else {
      newLink += `?t=${new Date().getTime()}`;
    }
    return `${newLink}#${links[1]}`;
  }
  return link;
};

const nullFun = () => {};

const getHelper = (obj, path = []) => (new Proxy(nullFun, {
  get(target, property) {
    return getHelper(obj, path.concat(property));
  },
  apply(target, self, args) {
    let val = obj;
    for (let i = 0; i < path.length; i += 1) {
      if (val === null || val === undefined) break;
      val = val[path[i]];
    }
    if (val === null || val === undefined) {
      val = args[0];
    }
    return val;
  },
}));

const parseJsonObject = (str) => {
  if (!str) return {};
  try {
    return JSON.parse(str);
  } catch (e) {
    return {};
  }
};

const parseJsonArray = (str) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return [];
  }
};
// 手机号脱敏
const phoneDesensitization = (value) => {
  if (!value) return;
  const pat = /(\d{3})\d*(\d{4})/;
  return value.replace(pat, '$1****$2');
};
// 删除左右两端的空格
function stringTrim(str) {
  return str.replace(/(^\s*)|(\s*$)/g, '');
}

/*
 * @describe 打开一个新窗口
 * 如果窗口阿凯失败则让当前窗口直接跳转
 * 如果传入窗口关闭回调，则启动定时器判断窗口是否关闭，如果窗口关闭则回调handler函数
 */
function openNewWin(url, closedHandler) {
  const newWin = window.open(url);
  if (!newWin) {
    window.location.href = url;
  }
  if (newWin && closedHandler) {
    const newWindowInterval = setInterval(() => {
      if (newWin.closed) {
        clearInterval(newWindowInterval);
        closedHandler(newWin);
      }
    }, 500);
  }
  return newWin;
}


/**
 * Checks if `value` is the
 * of `Object`. (e.g. arrays, functions, objects, regexes, `new Number(0)`, and `new String('')`)
 *
 * @since 0.1.0
 * @category Lang
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is an object, else `false`.
 * @example
 *
 * isObject({})
 * // => true
 *
 * isObject([1, 2, 3])
 * // => true
 *
 * isObject(Function)
 * // => true
 *
 * isObject(null)
 * // => false
 */
function isObject(value) {
  const type = typeof value;
  return value != null && (type === 'object' || type === 'function');
}

function createString() {
  return Math.floor(Math.random() * 2147483648).toString(36);
}

function titleCase(str) {
  if (!str) return '';
  return `${str[0].toLowerCase()}${str.substring(1)}`;
}

function checkPwdStrong(val, config) {
  // if (!str || str.length < 8) return false;
  // const TestReg = /^(?!\d+$)(?![a-zA-Z]+$)[a-zA-Z\d]+$/;
  // return TestReg.test(str);
  let reg;
  let number = true;
  let lowerCaseLetters = true;
  let uppercaseLetter = true;
  let specialCharacters = true;
  const minLength = val.length >= config.pwdMinLeng;
  if (config.pwdRule.includes(1)) {
    reg = new RegExp(/(?=.*\d)/);
    number = reg.test(val);
  }
  if (config.pwdRule.includes(2)) {
    reg = new RegExp(/(?=.*?[a-z])/);
    lowerCaseLetters = reg.test(val);
  }
  if (config.pwdRule.includes(4)) {
    reg = new RegExp(/(?=.*?[A-Z])/);
    uppercaseLetter = reg.test(val);
  }
  if (config.pwdRule.includes(8)) {
    reg = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]");
    specialCharacters = reg.test(val);
  }
  return minLength && number && lowerCaseLetters && uppercaseLetter && specialCharacters;
}

function realPx(px) {
  const maxWidth = window.innerWidth > 500 ? 500 : window.innerWidth;
  return px * (maxWidth / 375);
}

function px2rem(px) {
  const ratio = 375 / 10;
  return px / ratio;
}

export {
  isNull,
  isEmpty,
  isObject,
  deepClone,
  replaceStrVar,
  parseURL,
  isClass,
  isDate,
  isFunction,
  random,
  searchList,
  linkClearCache,
  getHelper,
  parseJsonObject,
  parseJsonArray,
  stringTrim,
  openNewWin,
  titleCase,
  createString,
  checkPwdStrong,
  realPx,
  px2rem,
  phoneDesensitization,
};
