import Vue from 'vue';
import Element from 'element-ui';
import '../style/element-variables.scss';

Element.Select.props.filterable = {
  type: Boolean,
  default: true
};
Element.Cascader.props.filterable = {
  type: Boolean,
  default: true
};
// Element.Input.props.focusSelect = {
//   type: Boolean,
//   default: false
// };
Element.Input.methods.handleFocus = function (event) {
  event.currentTarget.select();
  this.focused = true;
  this.$emit('focus', event);
}
Element.Cascader.methods.handleFocus = function (event) {
  event.target.select();
  this.$emit('focus', event);
}
Element.Autocomplete.methods.handleFocus = function (event) {
  event.currentTarget.select();
  this.activated = true;
  this.$emit('focus', event);
  if (this.triggerOnFocus) {
    this.debouncedGetData(this.value);
  }
}
Element.InputNumber.methods.handleFocus = function (event) {
  event.currentTarget.select();
  this.$emit('focus', event);
}

Vue.use(Element);
