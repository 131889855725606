
import Vue from 'vue';

import VueRouter from 'vue-router';
import store from '@/store';
import { getUserToken } from '@/global/tokenManager';
import routes from './routeMap';
import { checkIsMenu } from './menu';
import { getRoleTypeMenu } from "@/router/menu";
Vue.use(VueRouter);
import { MessageBox } from 'element-ui';
import { $sp_noEditLoginPwd } from "@/api/login";
const router = new VueRouter({
  mode: 'history',
  base: '/admin/',
  routes,
});


function authOnePage() {
  let pageList = localStorage.getItem('admin_login_user_auth');
  pageList=pageList?pageList.split(','):[]   
  let menuList = getRoleTypeMenu()
  let name=''
  menuList.map(item=>{
    if(item.name==pageList[0]){
      name=pageList[0]
      if(pageList.length>1&&item.children){
        item.children.map(item1=>{
          if(item1.name==pageList[1]){
            name=pageList[1]
          }
        })
      }
    }
  })  
  return name
}

function checkRouteAuth(name) {
  // if (name === 'AdminIndex') return true;
  if (name === 'ErrorPage') return true;
  const userType = localStorage.getItem('admin_login_user_root');
  if (userType === undefined) return false;
  if (userType === '1') return true;
  const authStr = localStorage.getItem('admin_login_user_auth');
  if (!authStr) return false;
  return authStr.split(',').includes(name);
}

function checkPassLoginValid(to) {
  return !to.name || ['Login', 'FindPassword'].includes(to.name);
}
router.beforeEach(async (to, from, next) => {
  console.log('to',to,'from',from, 'distribution');
  if (checkPassLoginValid(to)) { 
    next(); 
    return;
  }
  if (!getUserToken()) {
    next({
      name: 'Login',
    });
    return;
  }
  await store.dispatch('action_getLoginUserInfo').catch((error) => {
    console.error(error);
  });
  let toName = to.name;
  if (to.matched.length > 2) {
    toName = to.matched[1].name;
  }
  if((to.path!='/configurationAdmin/changePassword'&&to.path!='/login')&&store.state.userInfo&&store.state.userInfo.updatePassword==1){
    MessageBox({
      title:'提示',
      message:`你的密码已经连续使用超过${store.state.userInfo.days}天，为保护账号安全，建议您重新设置密码。`,
      type:'warning',
      // cancelButtonText:'暂不',
      confirmButtonText:'去修改',
      // showCancelButton:true,
      showClose: false,
      closeOnClickModal:false
    }).then(()=>{//去修改
      next({
        name: 'ChangePassword'
      }); 
    }).catch(()=>{//暂不
      $sp_noEditLoginPwd().then()
    })
  }
  console.log('checkIsMenu',checkIsMenu(toName),'checkRouteAuth',checkRouteAuth(toName),'checkRouteAuth-from',checkRouteAuth(from.name))
  if (checkRouteAuth(from.name)&&!checkRouteAuth(toName.name) ||checkIsMenu(toName) && checkRouteAuth(toName)) {
    next();
  }else{
    if(!checkIsMenu(toName)){
      if(to.fullPath=='/'){
        next({
          name: authOnePage(),
        });
        return
      }
      next();
    }else{
      next({
        name: 'ErrorPage',
      });
    }
  }
  
  // if (!checkIsMenu(toName)  || (checkIsMenu(toName) && checkRouteAuth(toName))) {
  //   next();
  //   return;
  // }
  // next({
  //   name: 'AdminIndex',
  // });
});

export default router;
