<template>
  <div class="smart-table-render">
    <div class="top-btns">
      <slot name="topBtn" :arr="selectedColumns" v-if="selectedColumns.length"></slot>
    </div>
    <el-table :data="tableData" style="width: 100%" :ref="tableOptions.name || 'tableRender'"
      :style="tableOptions.option && tableOptions.option.customStyle" :class="tableOptions.option && tableOptions.option.customClass"
      v-bind="tableOptions.option" v-loading="loading" element-loading-text="数据加载中..." @sort-change="sortChange"
      @row-click="rowClick"
      @selection-change="selectionChange" @expand-change="expandChange">
      <template #empty>
        <slot name="empty"></slot>
      </template>
      <!-- 内容列 -->
      <template v-for="(item, index) in needShowColumns">
        <el-table-column :key="index" v-bind="item.option" v-if="item">
          <!-- 自定义头部 -->
          <template :slot="item.slotHeader ? 'header' : undefined" slot-scope="scope">
            <i class="el-icon-s-fold open-setting-header" slot="reference" @click="openSetTitle" v-if="item.slotHeader === 'setTitle'"></i>
            <slot :name="item.slotHeader" :row="scope.row" v-else></slot>
          </template>
          <!-- 表格列内容 -->
          <template :slot="
              (item.widget && item.widget !== 'text') || item.colTemplate
                ? 'default'
                : undefined
            " slot-scope="scope">
            <!-- 特殊自定义显示 -->
            <span v-html="item.colTemplate && item.colTemplate(scope.row)" v-if="item.colTemplate"></span>
            <!-- 图片 -->
            <el-image :src="
                $imgPrefix(
                  scope.row[item.key],
                  '',
                  {
                    w: item.option.width,
                  }
                )
              "
              v-bind="item.option"
              :style="{ height: `${item.imgHeight || 40}px`, width: `${item.imgWidth}px` }" style="" v-else-if="item.widget === 'image' && scope.row[item.key]">
            </el-image>
            <!-- 链接 -->
            <el-link type="primary" :underline="false" v-else-if="item.widget === 'link'" @click="clickEvent(item.emit, scope.row)">
              {{ scope.row[item.key] }}
            </el-link>
            <!-- 按钮 -->
            <template v-else-if="item.widget === 'button'">
              <SmartTableBtn :item="item" :row="scope.row" @handleClick="
                  (val) => $emit(item.emit, scope.row, val, scope.$index)
                " />
            </template>
            <!-- 列展开插槽 -->
            <slot :name="item.slot" :row="scope.row" :index="scope.$index" v-else-if="item.slot" :item="item"></slot>
          </template>
          <template :slot="item.option.type === 'expand' ? 'default' : undefined" slot-scope="props">
            <slot :name="item.slot" :row="props.row"></slot>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <!-- 表格分页-->
    <div class="table-footer-info" :class="
        needPagination
          ? statisticalList && statisticalList.length
            ? 'between'
            : 'start'
          : 'end'
      ">
      <div class="statistical">
        <span v-for="(item, index) in statisticalList" :key="index" style="margin-right: 10px">
          <span>{{ item.label }}：</span>
          <span class="value-label">{{ item.value }}</span>
        </span>
      </div>
      <slot name="pageBefore"></slot>
      <el-pagination background :current-page.sync="currentPage" :page-sizes="pageSizes" :page-size="pageSize"
        @current-change="currentChange" @size-change="sizeChange" layout="total,prev, pager, next,sizes" :total="total"
        v-if="needPagination">
      </el-pagination>
    </div>

    <!-- 表格表头设置 -->
    <SetTableCol :columnsStatus="columnsStatus" :saveKey="tableOptions.name" :defaultShowCol="currentShowColumn"
      @closeMenuPopover="visible = false" @submitChoosedMenu="submitChoosedMenu" v-if="visible" />
  </div>
</template>
<script>
import { debounce, cloneDeep } from "@smart/util-base";
import SmartTableRender from "./SmartTableRender";
import SetTableCol from "./setTableCol.vue";
import SmartTableBtn from "./SmartTableBtn.vue";

const specialType = ["expand", "index", "selection", "*"];
export default {
  props: {
    needPagination: {
      // 是否需要分页
      type: Boolean,
      default: true,
    },
    searchParams: {
      // 获取数据参数
      type: Object,
      default: () => ({}),
    },
    tableOptions: {
      // 表格配置
      type: Object,
      default: () => ({}),
    },
    pageSizes: {
      // 表格页码配置
      type: Array,
      default: ()=>{
        return [20, 30, 40, 50];
      }
    },
    incomingTableData: Array, // 传入表格数据
    incomingStatisticalList: Array,
  },
  components: {
    SetTableCol,
    SmartTableBtn,
  },
  watch: {
    tableOptions: {
      handler(val) {
        this.tableColumnOptionsInfo = SmartTableRender(val);
        console.log(this.tableColumnOptionsInfo);
        if (!val || !val.defaultShowCol) return;
        const localColumnsData = localStorage.getItem(this.tableOptions.name);
        const defaultShowArr = localColumnsData
          ? JSON.parse(localColumnsData)
          : val.defaultShowCol || [];
        // 当前配置显示的列
        defaultShowArr.forEach((item) => {
          const resultItem = this.tableColumnOptionsInfo.find(
            (itemc) => item === itemc.key || item === `*${itemc.key}`
          );
          if (resultItem) {
            this.currentShowColumn.push({
              ...resultItem,
              show: true,
              disable: item.startsWith("*"),
            });
          }
        });
        this.tableColumnOptionsInfo.forEach((item) => {
          const result = defaultShowArr.find(
            (showCol) => showCol === item.key || showCol === `*${item.key}`
          );
          // 组装表格表头设置中展示列的数据：排除特殊类型、参与配置的列、表头不是表头设置按钮的列
          if (
            !specialType.includes(item.option.type) &&
            !item.hidden &&
            item.slotHeader !== "setTitle"
          ) {
            this.columnsStatus.push({
              ...item,
              show: !!result,
              disable: result && result.startsWith("*"),
            });
          }
        });
      },
      immediate: true,
      deep: true,
    },
    incomingTableData: {
      handler(val) {
        this.tableData = val || [];
      },
      immediate: true,
      deep: true,
    },
    incomingStatisticalList: {
      handler(val) {
        this.statisticalList = val || [];
      },
      deep: true,
    },
  },
  computed: {
    needShowColumns() {
      // 没有配置默认显示列 返回所有
      if (!this.currentShowColumn.length) return this.tableColumnOptionsInfo;
      const result = [];
      let beforeHiddenMaxIndex = 0; // 前面特殊列最大下标
      let canAdd = true; // 是否参与计数
      this.tableColumnOptionsInfo.forEach((item) => {
        if (
          specialType.includes(item.option.type) ||
          item.slotHeader === "setTitle" ||
          item.hidden
        ) {
          result.push(item);
          if (canAdd) {
            beforeHiddenMaxIndex += 1;
          }
        } else {
          canAdd = false;
        }
      });
      result.splice(beforeHiddenMaxIndex, 0, ...this.currentShowColumn);
      return result;
    },
  },
  data() {
    return {
      loading: false, // 表格loading
      visible: false, // 表格列控制弹框
      currentPage: 1, // 当前页
      pageSize: 20,
      total: 0, // 数据总条数
      tableData: [], // 当前列数据
      tableColumnOptionsInfo: [], // 所有列初始状态
      columnsStatus: [], // 所有列显示禁用状态
      currentShowColumn: [], // 当前配置显示的列
      selectedColumns: [], // 当前选中行,
      statisticalList: [], // 底部统计
    };
  },
  mounted() {
    this.pageSize = this.pageSizes[0];
    this.getData();
    window.addEventListener("resize", this.setTableHeight);
  },
  methods: {
    sizeChange(val) {
      this.pageSize = val;
      this.currentChange();
    },
    // 当用户对某一行展开或者关闭的时候会触发该事件
    expandChange(row, expandedRows) {
      this.$emit("expandChange", row, expandedRows);
    },
    // 表格的排序条件发生变化的时候会触发该事件
    sortChange(val) {
      this.$emit("sortChange", val);
    },
    rowClick(val) {
      this.$emit("rowClick", val);
    },
    // 选择项发生变化时会触发该事件
    selectionChange(val = "") {
      this.selectedColumns = val;
      this.$emit("selectionChange", val);
    },
    // 设置表格选中列
    toggleSelection(rows, key) {
      const ref = this.tableOptions.name || "tableRender";
      if (rows) {
        rows.forEach((row) => {
          const tableRow = this.tableData.find(
            (item) => item[key] === row[key]
          );
          if (!tableRow) return;
          this.toggleRowSelection(tableRow);
        });
      } else {
        this.$refs[ref].clearSelection();
      }
    },
    toggleRowSelection(tableRow) {
      const ref = this.tableOptions.name || "tableRender";
      this.$refs[ref].toggleRowSelection(tableRow);
    },
    // 全选
    toggleAllSelection() {
      const ref = this.tableOptions.name || "tableRender";
      this.$nextTick(() => {
        this.$refs[ref].toggleAllSelection();
      })
    },
    // 开启表格表头设置
    openSetTitle() {
      this.visible = !this.visible;
    },
    // 重置
    resetSearch(clearSelectedData = true) {
      this.currentPage = 1;
      this.total = 0;
      // this.tableData = [];
      this.getData();
      if (clearSelectedData) {
        this.selectedColumns = [];
      }
    },
    currentChange() {
      this.$emit("currentChange");
      this.getData();
    },
    // 获取表格数据
    getData() {
      // 没有获取数据方法 直接返回
      if (!this.tableOptions.getTableData) return;
      this.tableData = [];
      this.loading = true;
      this.tableOptions
        .getTableData(this.searchParams, this.currentPage, this.pageSize)
        .then((d) => {
          const { data, total, pageSize, statistical } = d || {};
          this.tableData = data || [];
          this.total = total || 0;
          if (pageSize) {
            this.pageSize = pageSize;
          }
          this.statisticalList = statistical || [];
          this.$emit('tableLoad')
        })
        .catch(() => {
          this.tableData = [];
          this.total = 0;
          this.pageSize = this.pageSizes[0];
          this.statisticalList = [];
        })
        .finally(() => {
          this.$emit('tableComplete')
          this.loading = false;
        });
    },
    // 链接点击
    clickEvent(emit, row, type = 0) {
      if (!emit) {
        throw new Error("缺少事件名");
      }
      this.$emit(emit, row, type);
    },
    // 设置显示列
    submitChoosedMenu(val) {
      this.loading = true;
      this.visible = false;
      this.currentShowColumn = cloneDeep(val);
      this.columnsStatus.forEach((item) => {
        if (!item.disable) {
          const result = val.find((itemc) => itemc.id === item.id);
          item.show = !!result;
        }
      });
      setTimeout(() => {
        this.setTableHeight();
        this.loading = false;
      }, 500);
    },
    // 设置表格数据
    setTableData(arr = []) {
      this.tableData = arr;
    },
    // 改变展开行
    setExpandRowKeys(expand) {
      const name = this.tableOptions.name
        ? this.tableOptions.name
        : "tableRender";
      const table = this.$refs[name];
      this.tableData.forEach((item) => {
        table.toggleRowExpansion(item, expand);
      });
    },
    // eslint-disable-next-line func-names
    setTableHeight: debounce(
      function () {
        const name = this.tableOptions.name
          ? this.tableOptions.name
          : "tableRender";
        const table = this.$refs[name];
        if (table) {
          table.doLayout();
        }
      },
      200,
      {
        leading: false,
        trailing: true,
      }
    ),
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setTableHeight);
  },
};
</script>

<style lang="scss">
.top-btns {
  position: absolute;
  width: 100%;
  top: -44px;
}
.smart-table-render {
  position: relative;
  .el-table-nobordercss {
    border-top: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;
    border-left: 1px solid #ebeef5;
  }
  .expanded > td {
    background: #eafbf2;
  }
  .el-table__expanded-cell {
    padding: 0;
  }
  .el-table--medium .el-table__cell.el-table__expanded-cell {
    padding: 0 !important;
  }
  .table-footer-info {
    display: flex;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
    padding-top: 10px;
    .statistical {
      box-sizing: border-box;
      padding-top: 10px;
      font-size: 14px;
      color: #303030;
      max-width: 56%;
      .value-label {
        color: #ff9f00;
      }
    }
  }
  .between {
    justify-content: space-between;
  }
  .end {
    justify-content: flex-end;
  }
  .start {
    justify-content: flex-start;
  }
}
.custem-popper.el-popover {
  padding: 0;
}
.open-setting-header {
  font-size: 20px;
}
</style>
