import Vue from 'vue';
import Vuex from 'vuex';

import { $get_loginUserMsg, $get_userRuleAuth } from '@/api/main';

Vue.use(Vuex);


export default new Vuex.Store({
  modules: {
  },
  state: {
    userInfo: JSON.parse(localStorage.getItem('agent_admin_user_info')) || {},
    system_cfg: JSON.parse(localStorage.getItem('agent_admin_system_cfg')) || {},
    customTabIndex:0
  },
  getters: {
    userWarehouseId(state){
      return state.userInfo?.warehouseIds?.[0] || '';
    },
    userRoleId(state){
      return state.userInfo?.roleId || 1;
    },
    tenantConfig(state){
      return state.userInfo?.tenantConfigVO || {};
    }
  },
  actions: {
    async action_getUserAuth() {
      const userAuthInfo = await $get_userRuleAuth();
      if (!userAuthInfo.isAdmin) {
        localStorage.setItem('admin_login_user_root', 0);
        localStorage.setItem('admin_login_user_auth', userAuthInfo.auth_config);
      } else {
        localStorage.setItem('admin_login_user_root', 1);
      }
      return userAuthInfo;
    },
    async action_getLoginUserInfo({ commit }) {
      const resp = await $get_loginUserMsg();
      const payChannel=localStorage.getItem('pay_channel')
      console.log('payChannel',payChannel)
      if(payChannel==1&&resp.node){
        if(resp.node.indexOf(',PlatformSettlement,') >-1){//财务-平台结算
          resp.node=resp.node.replace(',PlatformSettlement,',',')
        }
        if(resp.node.indexOf(',Settlement,') >-1){//财务-结算
          resp.node=resp.node.replace(',Settlement,',',')
        }
        if(resp.node.indexOf(',SettlementSettings,') >-1){//财务-结算设置
          resp.node=resp.node.replace(',SettlementSettings,',',')
        }
        // console.log('resp.node',resp.node)
      }
      // roleId 1 平台 2 采购员 3 分拣员 4 供货商 5  客户 6 客户子账号
      localStorage.setItem('admin_login_user_type', resp.roleId || 1);
      if (!resp.isAdmin) {
        localStorage.setItem('admin_login_user_root', 0);
        localStorage.setItem('admin_login_user_auth', resp.node||'');
        localStorage.setItem('admin_login_user_button', resp.button||'');
      } else {
        localStorage.setItem('admin_login_user_root', 1);
        localStorage.setItem('admin_login_user_auth', resp.node||'');
        localStorage.setItem('admin_login_user_button', resp.button||'');        
      }
      commit('setUserInfo', {
        ...resp,
        node: undefined,
        button: undefined,
      });
    },
  },
  mutations: {
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
      const info = JSON.stringify(userInfo);
      // const platform = {
      //   1: 'admin',
      //   4: 'supplier'
      // }
      
      localStorage.setItem(`agent_admin_user_info`, info)
    },
    setSystemCfg(state, system_cfg) {
      state.system_cfg = system_cfg
      const info = JSON.stringify(system_cfg);
      localStorage.setItem(`agent_admin_system_cfg`, info)
    },
  },
});
