<template>
  <div class="fixed-template-page flexbox flex-tb">
    <header>
      <slot name="header"></slot>
    </header>
    <main class="fixed-template-page-main flex_1">
      <slot></slot>
    </main>
    <footer>
      <slot name="footer"></slot>
    </footer>

  </div>
</template>

<script>
export default {
  name: 'FixedTemplate',
};
</script>

<style lang="scss">
.fixed-template-page{
  height: 100%;
  overflow: hidden;
  >main{
    overflow-y: auto;
    position: relative;
  }
  header + main>.smart-table-render:first-child{
    height: 100%;
  }
}
</style>
