<template>
  <div class="input-range-item">
    <el-input v-model="left_text" v-bind="option"></el-input>
    <span>-</span>
    <el-input v-model="right_text" v-bind="option"></el-input>
  </div>
</template>
<script>
export default {
  name: 'textRange',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    option: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      left_text: '',
      right_text: '',
    };
  },
  watch: {
    value(val) {
      this.left_text = val[0] || '';
      this.right_text = val[1] || '';
    },
    left_text(val) {
      this.$emit('input', [this.left_text, this.right_text]);
    },
    right_text(val) {
      this.$emit('input', [this.left_text, this.right_text]);
    },
  },
  methods: {
  },
  mounted() {
  },
};
</script>
<style lang="scss">
  .input-range-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .el-input {
      width: 90px;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
    }
    input[type='number'] {
      -moz-appearance: textfield !important;
    }
  }
</style>
