const STORE_KEY = 'LOGGER_STORE_KEY';
let currentSource;
let clientUUID;
const commontags = {};

function httpRequest(postBody) {
  const host = 'cn-hangzhou.log.aliyuncs.com';
  const project = 'songshu-web';
  const logstore = 'client-log';
  const url = `${window.location.protocol}//${project}.${host}/logstores/${logstore}/track?APIVersion=0.6.0`;
  const bodyStr = JSON.stringify(postBody);
  const httpRequestIns = new XMLHttpRequest();
  httpRequestIns.open('POST', url, true);
  httpRequestIns.setRequestHeader('x-log-bodyrawsize', bodyStr.length);
  httpRequestIns.setRequestHeader('x-log-apiversion', '0.6.0');
  httpRequestIns.send(bodyStr);
}

function setStore(key, obj) {
  localStorage.setItem(key, JSON.stringify(obj));
}

function getStore(key) {
  return JSON.parse(localStorage.getItem(key) || '[]');
}

export const generalClientUUID = (targetFlag) => targetFlag;

export const setLoggerOptions = (options) => {
  currentSource = options.source;
};

export const setClientUUID = (uuid) => {
  clientUUID = uuid;
};

export const setCommonTags = (key, value) => {
  if (!key || !value) return;
  commontags[key] = value;
};

async function getPostDataList() {
  const loggerList = getStore(STORE_KEY);
  if (!loggerList || loggerList.length === 0) return Promise.reject();
  const logs = [];
  let tags = {};
  loggerList.forEach((loggerItem) => {
    const tag = loggerItem.tag;
    const level = loggerItem.level;
    const logItem = {};
    Object.keys(loggerItem).forEach((keyItem) => {
      if (keyItem === 'tag' || keyItem === 'level') return;
      if (Array.isArray(loggerItem[keyItem]) || typeof loggerItem[keyItem] === 'number' || typeof loggerItem[keyItem] === 'object') {
        logItem[keyItem] = JSON.stringify(loggerItem[keyItem]);
      } else if (loggerItem[keyItem] === undefined) {
        logItem[keyItem] = JSON.stringify(loggerItem[keyItem]);
      } else {
        logItem[keyItem] = loggerItem[keyItem];
      }
    });
    logs.push(logItem);

    if (tag && typeof tag === 'object') {
      tags = {
        ...tags,
        ...tag,
        level,
      };
    } else {
      tags.level = level;
    }

    return false;
  });

  setStore(STORE_KEY, []);
  return {
    tags, logs,
  };
}

async function buildPostData() {
  const { tags, logs } = await getPostDataList();
  const postBody = {
    __topic__: clientUUID || '',
    __source__: currentSource || '',
    __tags__: {
      ...commontags,
      ...tags,
    },
    __logs__: logs,
  };
  return postBody;
}

let tempTimerNo;
let reportRequestIng = false;
async function reportLogger() {
  if (reportRequestIng) return;
  if (tempTimerNo) {
    clearTimeout(tempTimerNo);
    tempTimerNo = undefined;
  }
  reportRequestIng = true;
  try {
    const postBody = await buildPostData();
    httpRequest(postBody);
    tempTimerNo = setTimeout(reportLogger, 1000);
  } catch (e) {
    console.log(e);
  }
  reportRequestIng = false;
}


export const pushLoggerStore = async (logItem) => {
  const loggerList = getStore(STORE_KEY) || [];
  loggerList.push(logItem);
  setStore(STORE_KEY, loggerList);
  reportLogger();
};

function slsLogReporter(args, level) {
  const logTemp = {};
  args.forEach((item, index) => {
    logTemp[`args_${index}`] = item;
  });
  const loggerItem = {
    ...logTemp,
    href: location.href,
    level,
  };
  pushLoggerStore(loggerItem);
}

export default {
  debug(...args) {
    slsLogReporter(args, 'debug');
    return this;
  },
  info(...args) {
    slsLogReporter(args, 'info');
    return this;
  },
  warn(...args) {
    slsLogReporter(args, 'warn');
    return this;
  },
  error(...args) {
    slsLogReporter(args, 'error');
    return this;
  },
  in() {
    return this;
  },
  setFilterMsg(msg) { // 从基础库2.7.3开始支持
    if (typeof msg !== 'string') return this;
    pushLoggerStore({
      content: msg,
      tag: {
        filter: msg,
      },
      level: 'filter',
    });
    return this;
  },
  addFilterMsg(msg) { // 从基础库2.8.1开始支持
    if (typeof msg !== 'string') return this;
    pushLoggerStore({
      content: msg,
      tag: {
        filter: msg,
      },
      level: 'filter',
    });
    return this;
  },
};
