<template>
  <el-drawer :size="460" :append-to-body="true" :visible.sync="visible" :with-header="false" :before-close="handleClose">
    <div class="notice-content" v-if="!showDetail">
      <p class="notice-list-title">公告通知</p>
      <div class="notice-btn fc">
        <el-button type="primary" size="medium" @click="readAllNotice" plain>全部已读</el-button>
        <el-button type="primary" size="medium" @click="clearNoticeList" plain>清空</el-button>
      </div>
      <ul class="notice-list-data" v-loading="noticeLoading" element-loading-text="加载中...">
        <li :class="['notice-item',item.state==0?'item-gray':'']" v-for="item in noticeList" :key="item.id" @click="goNoticeDetail(item)">
          <div class="fc">
            <p class="item-time">{{ formatTime(item.createTime) }}</p>
            <p class="item-title">{{ item.title }}</p>
          </div>
          <div class="item-content" v-html="item.content"></div>
        </li>
      </ul>
    </div>
    <div class="notice-detail" v-else>
      <div class="header-box fc" @click="goNoticeList">
        <i class="el-icon-arrow-left"></i>
        <p>返回</p>
      </div>
      <div :class="['detail-box',noticeDetail.state==0?'item-gray':'']">
        <p class="detail-title">{{ noticeDetail.title }}</p>
        <p class="detail-time">{{ noticeDetail.createTime }}</p>
        <p class="detail-content" v-html="noticeDetail.content"></p>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { 
  $sp_queryNoticeList,
  $sp_queryNoticeInfo,
  $sp_readAllNotice,
  $sp_clearAllNotice,
 } from '@/api/notice';
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDetail: false,
      noticeList: [],
      noticeLoading: false,
      noticeDetail: {},
    };
  },
  created() {
    this.queryNoticeList();
  },
  methods: {
    // 获取公告列表
    queryNoticeList() {
      this.taskLoading = true;
      $sp_queryNoticeList().then(res => {
        this.noticeList = res || [];
      }).finally(() => {
        this.taskLoading = false;
      })
    },
    // 公告全部已读
    readAllNotice() {
      $sp_readAllNotice().then(res => {
        this.noticeList = res || [];
      });
    },
    // 清空公告
    clearNoticeList() {
      if (!this.noticeList.length) {
        this.$message.error('暂无可清空的公告');
        return;
      }
      $sp_clearAllNotice().then(() => {
        this.queryNoticeList();
      })
    },
    // 显示自定义格式化时间
    formatTime(time) {
      return time
    },
    handleClose() {
      this.showDetail = false
      this.$emit('close')
    },
    goNoticeDetail(item) {
      $sp_queryNoticeInfo({ id:item.id }).then(res => {
        this.noticeDetail = res || {};
        this.showDetail = true
      });
    },
    goNoticeList() {
      this.showDetail = false;
      this.queryNoticeList();
    },
  },
};
</script>

<style scoped lang="scss">
.fc {
  display: flex;
  align-items: center;
}
.item-gray {
    color: #999;
  }
.notice-content {
  height: 100%;
  overflow: hidden;
  .notice-list-title {
    width: 460px;
    box-sizing: border-box;
    padding: 10px 10px 0;
    line-height: 50px;
    border-bottom: 1px solid #ececec;
  }
  .notice-btn {
    padding: 10px;
    border-bottom: 1px solid #ececec;
    & > :last-child {
      margin-left: 20px;
    }
  }
  .notice-list-data {
    height: calc(100% - 111px);
    overflow: auto;
    box-sizing: border-box;
    padding: 10px;
  }
  .notice-item {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #ececec;
    cursor: pointer;
    .item-time {
      font-size: 12px;
    }
    .item-title {
      padding-left: 20px;
      font-size: 14px;
    }
    .item-content {
      padding-top: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      font-size: 12px;
    }
  }
}
.notice-detail {
  .header-box {
    box-sizing: border-box;
    padding: 10px 10px 0;
    border-bottom: 1px solid #ececec;
    cursor: pointer;
    .el-icon-arrow-left {
      font-size: 18px;
    }
    & > :last-child {
      font-size: 14px;
      line-height: 50px;
    }
  }
  .detail-box {
    padding: 20px 10px;
    font-size: 14px;
    .detail-title {
      font-size: 16px;
      text-align: center;
    }
    .detail-time {
      text-align: right;
      margin-top: 30px;
    }
    .detail-content {
      margin-top: 30px;
      line-height: 22px;
    }
  }
}
</style>
