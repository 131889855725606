import { debounce } from '@smart/util-web';
export default {
  inserted(el, binding, vNode) {
    const ele = el.tagName === 'INPUT' ? el : el.querySelector('input');
    const rule = binding.value || {};
    const partNum = rule.float ?? 2
    const reg = new RegExp('^(-)*(\\d+)\\.(\\d{0,' + partNum + '}).*$')
    ele.oninput = debounce(function () {
      let val = ele.value;
      val = val.replace(/[^\d.]/g, ''); // 清除“数字”和“.”以外的字符
      val = val.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
      val = val.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      // eslint-disable-next-line prefer-template
      if ((val + '').startsWith('.')) val = '0' + val;
      if (val.indexOf('.') < 0 && val !== '') { // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        val = parseFloat(val);
      }
      if (rule.float === 0) {
        val = Math.trunc(val)
      } else {
        // eslint-disable-next-line no-useless-escape
        val = String(val).replace(reg, '$1$2.$3'); // 保留2位小数
      }
      if (rule.min && val < rule.min) {
        val = rule.min
      }
      if (rule.max && val > rule.max) {
        val = rule.max
      }
      if (vNode.componentInstance) {
        vNode.componentInstance.$emit('input', val)
      }
    }, 500, {
      leading: false,
      trailing: true,
    })
  }
}