import { loadCatch } from "@smart/util-web";
import MainContainer from "../views/main/MainContainer.vue";
import ErrorPage from "../views/errorPage/index.vue";
const routes = [
  {
    path: "/index",
    name: "Index",
    component: MainContainer,
    redirect: "index/adminIndex",
    children: [
      {
        path: "adminIndex",
        name: "AdminIndex",
        component: () => import(/* webpackChunkName: "goods" */ "../views/main/AdminIndex.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
      },
    ],
  },
  {
    path: "/goods",
    name: "Goods",
    component: MainContainer,
    children: [
      {
        path: "addGoodsRecord",
        name: "AddGoodsRecord",
        component: () => import(/* webpackChunkName: "goods" */ "../views/goods/addGoodsRecord.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
      },
      {
        path: "/",
        name: "GoodsFiles",
        component: () => import(/* webpackChunkName: "goods" */ "../views/goods/goodsFiles.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
        redirect: "goods/goodsFiles",
        children: [
          {
            path: "goodsFiles",
            name: "GoodsFiles",
            component: () => import("../views/goods/goodsFilesList.vue").catch(loadCatch),
            meta: {
              cache: true,
            },
          },
          {
            path: "goodsDifference",
            name: "GoodsDifference",
            component: () => import("../views/goods/goodsDifference.vue").catch(loadCatch),
            meta: {
              cache: true,
            },
          },
        ],
      },
      {
        path: "goodsTypes",
        name: "GoodsTypes",
        component: () => import(/* webpackChunkName: "goods" */ "../views/goods/goodsTypes.vue").catch(loadCatch),
        meta: {
          cache: true,
        },
      },
      {
        path: "secondaryData",
        name: "SecondaryData",
        component: () => import(/* webpackChunkName: "goods" */ "../views/goods/secondaryData.vue").catch(loadCatch),
        meta: {
          cache: true,
        },
      },
      {
        path: "goodsDemand",
        name: "GoodsDemand",
        component: () => import(/* webpackChunkName: "goods" */ "../views/goods/goodsDemand.vue").catch(loadCatch),
        meta: {
          cache: true,
        },
      },
      {
        path: "goodsAudit",
        name: "GoodsAudit",
        component: () => import(/* webpackChunkName: "goods" */ "../views/goods/goodsAudit.vue").catch(loadCatch),
        meta: {
          cache: true,
        },
        redirect: "/goods/goodsAudit/editList",
        children: [
          {
            path: "editList",
            name: "GoodsAuditList",
            component: () => import("../views/goods/goodsAuditList.vue").catch(loadCatch),
            meta: {
              cache: true,
            },
          },
          {
            path: "newGoods",
            name: "GoodsAuditNewList",
            component: () => import("../views/goods/goodsAuditNewList.vue").catch(loadCatch),
            meta: {
              cache: true,
            },
          },
        ],
      },
      {
        path: "recycleBin",
        name: "RecycleBin",
        component: () => import(/* webpackChunkName: "goods" */ "../views/goods/recycleBin.vue").catch(loadCatch),
        meta: {
          cache: true,
        },
      },
      {
        path: "goodsCheck",
        name: "GoodsCheck",
        component: () => import(/* webpackChunkName: "goods" */ "../views/goods/goodsCheck.vue").catch(loadCatch),
        meta: {
          cache: true,
        },
        redirect: "/goods/goodsCheck/report",
        children: [
          {
            path: "report",
            name: "GoodsCheckReport",
            component: () => import("../views/goods/goodsCheckReport.vue").catch(loadCatch),
            meta: {
              cache: true,
            },
          },
          {
            path: "reportInfo",
            name: "GoodsCheckReportInfo",
            component: () => import("../views/goods/goodsCheckReportInfo.vue").catch(loadCatch),
            meta: {
              cache: true,
            },
          },          
          {
            path: "search",
            name: "GoodsCheckSearch",
            component: () => import("../views/goods/goodsCheckSearch.vue").catch(loadCatch),
            meta: {
              cache: true,
            },
          },
          {
            path: "attrset",
            name: "GoodsCheckAttrset",
            component: () => import("../views/goods/goodsCheckAttrset.vue").catch(loadCatch),
            meta: {
              cache: true,
            },
          },          
        ],
      },      
    ],
  },
  {
    path: "/cookbook",
    name: "Cookbook",
    component: MainContainer,
    children: [
      {
        path: "cookbookOrder",
        name: "CookbookOrder",
        component: () => import("../views/cookbook/cookbookOrder.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
      },
      {
        path: "cookbookOrderAdd",
        name: "CookbookOrderAdd",
        component: () => import("../views/cookbook/cookbookOrderAdd.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
      },
      {
        path: "cookbookFiles",
        name: "CookbookFiles",
        component: () => import("../views/cookbook/cookbookFiles.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
      },
      {
        path: "cookbookFilesAdd",
        name: "CookbookFilesAdd",
        component: () => import("../views/cookbook/cookbookFilesAdd.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
      },
      {
        path: "mealtimes",
        name: "Mealtimes",
        component: () => import("../views/cookbook/mealtimes.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
      }
    ]
  },
  {
    path: "/order",
    name: "Order",
    component: MainContainer,
    children: [
      {
        path: "orderList",
        name: "OrderList",
        component: () => import(/* webpackChunkName: "warehouse" */ "../views/order/orderList.vue").catch(loadCatch),
        meta: {
          cache: true,
        },
      },
      {
        path: "orderAdd",
        name: "OrderAdd",
        component: () => import(/* webpackChunkName: "warehouse" */ "../views/order/orderAdd.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
      },
      {
        path: "orderDetail",
        name: "OrderDetail",
        component: () => import(/* webpackChunkName: "warehouse" */ "../views/order/orderDetail.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
      },
      {
        path: "orderCheck",
        name: "OrderCheck",
        component: () => import(/* webpackChunkName: "warehouse" */ "../views/order/orderCheck.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
      },
      {
        path: "orderSummary",
        name: "OrderSummary",
        component: () => import(/* webpackChunkName: "warehouse" */ "../views/order/orderSummary.vue").catch(loadCatch),
        meta: {
          cache: true,
        },
      },
      {
        path: "orderRefund",
        name: "OrderRefund",
        component: () => import(/* webpackChunkName: "warehouse" */ "../views/order/orderRefund.vue").catch(loadCatch),
        meta: {
          cache: true,
        },
      },
      {
        path: "orderReturnAdd",
        name: "OrderReturnAdd",
        component: () => import(/* webpackChunkName: "warehouse" */ "../views/order/orderReturnAdd.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
      },
      {
        path: "orderReturnDetail",
        name: "OrderReturnDetail",
        component: () => import(/* webpackChunkName: "warehouse" */ "../views/order/orderReturnDetail.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
      },
      {
        path: "orderAbnormal",
        name: "OrderAbnormal",
        component: () => import(/* webpackChunkName: "warehouse" */ "../views/order/orderAbnormal.vue").catch(loadCatch),
        meta: {
          cache: true,
        },
      },
    ],
  },
  {
    path: "/depositBasket",
    name: "DepositBasket",
    component: MainContainer,
    children: [
      {
        path: "basketList",
        name: "BasketList",
        component: () => import(/* webpackChunkName: "depositBasket" */ "../views/depositBasket/basketList.vue").catch(loadCatch),
        meta: {
          cache: true,
        },
      },
      {
        path: "borrowAndReturnManagement",
        name: "BorrowAndReturnManagement",
        component: () =>
          import(/* webpackChunkName: "depositBasket" */ "../views/depositBasket/borrowAndReturnManagement.vue").catch(loadCatch),
        meta: {
          cache: true,
        },
      },
      {
        path: "borrowAndReturnStatistical",
        name: "BorrowAndReturnStatistical",
        component: () =>
          import(/* webpackChunkName: "depositBasket" */ "../views/depositBasket/borrowAndReturnStatistical.vue").catch(loadCatch),
        meta: {
          cache: true,
        },
      },
    ],
  },
  {
    path: "/warehouse",
    name: "Warehouse",
    component: MainContainer,
    children: [
      {
        path: "goodsSorting",
        name: "GoodsSorting",
        component: () => import(/* webpackChunkName: "warehouse" */ "../views/warehouse/goodsSorting/goodsSorting.vue").catch(loadCatch),
        meta: {
          cache: true,
        },
      },
      {
        path: "deliverGoods",
        name: "DeliverGoods",
        component: () => import(/* webpackChunkName: "warehouse" */ "../views/warehouse/deliverGoods/deliverGoods.vue").catch(loadCatch),
        meta: {
          cache: true,
        },
      },
      {
        path: "putInStorage",
        name: "PutInStorage",
        component: () => import(/* webpackChunkName: "warehouse" */ "../views/warehouse/putInStorage/putInStorage.vue").catch(loadCatch),
        meta: {
          cache: true,
        },
      },
      {
        path: "addPutInStorage",
        name: "AddPutInStorage",
        component: () => import(/* webpackChunkName: "warehouse" */ "../views/warehouse/putInStorage/addPutInStorage.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
      },
      {
        path: "stockInAndStockOut",
        name: "StockInAndStockOut",
        component: () =>
          import(/* webpackChunkName: "warehouse" */ "../views/warehouse/stockInAndStockOut/stockInAndStockOut.vue").catch(loadCatch),
        meta: {
          cache: true,
        },
      },
      {
        path: "inventory",
        name: "Inventory",
        component: () => import(/* webpackChunkName: "warehouse" */ "../views/warehouse/inventory/inventory.vue").catch(loadCatch),
        meta: {
          cache: true,
        },
      },
      {
        path: "addCostChange",
        name: "AddCostChange",
        component: () => import(/* webpackChunkName: "warehouse" */ "../views/warehouse/inventory/addCostChange.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
      },
      {
        path: "stockInAndStockOutDetail",
        name: "StockInAndStockOutDetail",
        component: () =>
          import(/* webpackChunkName: "warehouse" */ "../views/warehouse/stockInAndStockOut/stockInAndStockOutDetail.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
      },
      {
        path: "goodsTransfers",
        name: "GoodsTransfers",
        component: () =>
          import(/* webpackChunkName: "warehouse" */ "../views/warehouse/goodsTransfers/goodsTransfers.vue").catch(loadCatch),
        meta: {
          cache: true,
        },
      },
      {
        path: "addOrEditGoodsTransfers",
        name: "AddOrEditGoodsTransfers",
        component: () =>
          import(/* webpackChunkName: "warehouse" */ "../views/warehouse/goodsTransfers/addOrEditGoodsTransfers.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
      },
      {
        path: "goodsTransferDetail",
        name: "GoodsTransferDetail",
        component: () =>
          import(/* webpackChunkName: "warehouse" */ "../views/warehouse/goodsTransfers/goodsTransferDetail.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
      },
      {
        path: "reportedLossToOverflow",
        name: "ReportedLossToOverflow",
        component: () =>
          import(/* webpackChunkName: "warehouse" */ "../views/warehouse/reportedLossToOverflow/reportedLossToOverflow.vue").catch(
            loadCatch
          ),
        meta: {
          cache: true,
        },
      },
      {
        path: "addOrEditReportedLossToOverflow",
        name: "AddOrEditReportedLossToOverflow",
        component: () =>
          import(/* webpackChunkName: "warehouse" */ "../views/warehouse/reportedLossToOverflow/addOrEditReportedLossToOverflow.vue").catch(
            loadCatch
          ),
        meta: {
          cache: false,
        },
      },
      {
        path: "reportedLossToOverflowDetail/:recordNumber",
        name: "ReportedLossToOverflowDetail",
        component: () =>
          import(/* webpackChunkName: "warehouse" */ "../views/warehouse/reportedLossToOverflow/reportedLossToOverflowDetail.vue").catch(
            loadCatch
          ),
        meta: {
          cache: false,
        },
      },
      {
        path: "stockTaking",
        name: "StockTaking",
        component: () => import(/* webpackChunkName: "warehouse" */ "../views/warehouse/stockTaking/stockTaking.vue").catch(loadCatch),
        meta: {
          cache: true,
        },
      },
      {
        path: "addOrEditStockTaking",
        name: "AddOrEditStockTaking",
        component: () =>
          import(/* webpackChunkName: "warehouse" */ "../views/warehouse/stockTaking/addOrEditStockTaking.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
      },
      {
        path: "stockTakingDetail/:id",
        name: "StockTakingDetail",
        component: () =>
          import(/* webpackChunkName: "warehouse" */ "../views/warehouse/stockTaking/stockTakingDetail.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
      },
      {
        path: "warehouseManagement",
        name: "WarehouseManagement",
        component: () =>
          import(/* webpackChunkName: "warehouse" */ "../views/warehouse/warehouseManagement/warehouseManagement.vue").catch(loadCatch),
        meta: {
          cache: true,
        },
      },
      {
        path: "addOrEditManagement",
        name: "AddOrEditManagement",
        component: () =>
          import(/* webpackChunkName: "warehouse" */ "../views/warehouse/warehouseManagement/addOrEditManagement.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
      },
      {
        path: "managementDetail/:id",
        name: "ManagementDetail",
        component: () =>
          import(/* webpackChunkName: "warehouse" */ "../views/warehouse/warehouseManagement/managementDetail.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
      },
      {
        path: "sorter",
        name: "Sorter",
        component: () => import(/* webpackChunkName: "warehouse" */ "../views/warehouse/sorter.vue").catch(loadCatch),
        meta: {
          cache: true,
        },
      },
      {
        path: "warehouseInfo",
        name: "WarehouseInfo",
        component: () => import(/* webpackChunkName: "warehouse" */ "../views/warehouse/warehouseInfo.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
      },
      {
        path: "printThreshold",
        name: "PrintThreshold",
        component: () => import("../views/warehouse/printThreshold.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
      },
    ],
  },
  {
    path: "/purchase",
    name: "Purchase",
    component: MainContainer,
    children: [
      {
        path: "purchaseOrder",
        name: "PurchaseOrder",
        component: () => import(/* webpackChunkName: "purchase" */ "../views/purchase/purchaseOrder.vue").catch(loadCatch),
        meta: {
          cache: true,
        },
      },
      {
        path: "purchaseOrderDetail",
        name: "PurchaseOrderDetail",
        component: () => import(/* webpackChunkName: "purchase" */ "../views/purchase/purchaseOrderDetail.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
      },
      {
        path: "purchaseOrderEditAbout",
        name: "PurchaseOrderEditAbout",
        component: () => import(/* webpackChunkName: "purchase" */ "../views/purchase/purchaseOrderEditAbout.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
      },
      {
        path: "purchaseOrderAddAndEdit",
        name: "PurchaseOrderAddAndEdit",
        component: () => import(/* webpackChunkName: "purchase" */ "../views/purchase/purchaseOrderAddAndEdit.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
      },
      {
        path: "purchaseReturn",
        name: "PurchaseReturn",
        component: () => import(/* webpackChunkName: "purchase" */ "../views/purchase/purchaseReturn.vue").catch(loadCatch),
        meta: {
          cache: true,
        },
      },
      {
        path: "purchaseReturnAddOrEdit",
        name: "PurchaseReturnAddOrEdit",
        component: () => import(/* webpackChunkName: "purchase" */ "../views/purchase/purchaseReturnAddOrEdit.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
      },
      {
        path: "purchaseReturnDetail",
        name: "PurchaseReturnDetail",
        component: () => import(/* webpackChunkName: "purchase" */ "../views/purchase/purchaseReturnDetail.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
      },
      {
        path: "purchaseReturnSourceOrder",
        name: "PurchaseReturnSourceOrder",
        component: () => import(/* webpackChunkName: "purchase" */ "../views/purchase/purchaseReturnSourceOrder.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
      },
      {
        path: "purchaseHistory",
        name: "PurchaseHistory",
        component: () => import(/* webpackChunkName: "purchase" */ "../views/purchase/purchaseHistory.vue").catch(loadCatch),
        meta: {
          cache: true,
        },
      },
      {
        path: "smartPurchase",
        name: "SmartPurchase",
        component: () => import(/* webpackChunkName: "purchase" */ "../views/purchase/smartPurchase.vue").catch(loadCatch),
        meta: {
          cache: true,
        },
      },
      {
        path: "changePurchasingCost",
        name: "ChangePurchasingCost",
        component: () => import(/* webpackChunkName: "purchase" */ "../views/purchase/changePurchasingCost.vue").catch(loadCatch),
        meta: {
          cache: true,
        },
      },
      {
        path: "suppliers",
        name: "Suppliers",
        component: () => import(/* webpackChunkName: "purchase" */ "../views/purchase/suppliers.vue").catch(loadCatch),
        meta: {
          cache: true,
        },
      },
      {
        path: "suppliersEditAndAdd",
        name: "SuppliersEditAndAdd",
        component: () => import(/* webpackChunkName: "purchase" */ "../views/purchase/suppliersEditAndAdd.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
      },
      {
        path: "suppliersLabel",
        name: "SuppliersLabel",
        component: () => import("../views/purchase/suppliersLabel.vue").catch(loadCatch),
      },
      {
        path: "suppliersLabelDetail",
        name: "SuppliersLabelDetail",
        component: () => import("../views/purchase/suppliersLabelDetail.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
      },
      {
        path: "purchaser",
        name: "Purchaser",
        component: () => import(/* webpackChunkName: "purchase" */ "../views/purchase/purchaser.vue").catch(loadCatch),
        meta: {
          cache: true,
        },
      },
      {
        path: "purchaserEditAndAdd",
        name: "PurchaserEditAndAdd",
        component: () => import(/* webpackChunkName: "purchase" */ "../views/purchase/purchaserEditAndAdd.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
      },
    ],
  },
  {
    path: "/distribution",
    name: "Distribution",
    component: MainContainer,
    children: [
      {
        path: "distributionMap",
        name: "DistributionMap",
        component: () => import("../views/distribution/distributionMap.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
      },
      {
        path: "distributionRoute",
        name: "DistributionRoute",
        component: () => import("../views/distribution/distributionRoute.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
        redirect: "distributionRoute/routeList",
        children: [
          {
            path: "routeList",
            name: "RouteList",
            component: () => import("../views/distribution/disRoute/routeList.vue").catch(loadCatch),
            meta: {
              cache: true,
            },
          },
          {
            path: "routeClientList",
            name: "RouteClientList",
            component: () => import("../views/distribution/disRoute/routeClientList.vue").catch(loadCatch),
            meta: {
              cache: true,
            },
          },
          {
            path: "routeOrderList",
            name: "RouteOrderList",
            component: () => import("../views/distribution/disRoute/routeOrderList.vue").catch(loadCatch),
            meta: {
              cache: true,
            },
          },
        ],
      },
      {
        path: "logisticsRowLine",
        name: "LogisticsRowLine",
        component: () => import("../views/distribution/logisticsRowLine.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
        redirect: "logisticsRowLine/listMode",
        children: [
          {
            path: "listMode",
            name: "ListMode",
            component: () => import("../views/distribution/rowLine/listMode.vue").catch(loadCatch),
            meta: {
              cache: false,
            },
          },
          {
            path: "mapMode",
            name: "MapMode",
            component: () => import("../views/distribution/rowLine/mapMode.vue").catch(loadCatch),
            meta: {
              cache: false,
            },
          },
        ],
      },
      {
        path: "lunchLogisticsRowLine",
        name: "LunchLogisticsRowLine",
        component: () => import("../views/distribution/lunchLogisticsRowLine.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
        redirect: "lunchLogisticsRowLine/lunchListMode",
        children: [
          {
            path: "lunchListMode",
            name: "LunchListMode",
            component: () => import("../views/distribution/rowLine/listMode.vue").catch(loadCatch),
            meta: {
              cache: false,
            },
          },
          {
            path: "lunchMapMode",
            name: "LunchMapMode",
            component: () => import("../views/distribution/rowLine/mapMode.vue").catch(loadCatch),
            meta: {
              cache: false,
            },
          },
        ],
      },
      {
        path: "area",
        name: "Area",
        component: () => import("../views/distribution/area.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
      },
      {
        path: "driver",
        name: "Driver",
        component: () => import("../views/distribution/driver.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
      },
      {
        path: "freightSet",
        name: "FreightSet",
        component: () => import("../views/distribution/freightSet.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
      },
      {
        path: "clientFreight",
        name: "ClientFreight",
        component: () => import("../views/distribution/clientFreight.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
      },
    ],
  },
  {
    path: "/customer",
    name: "Customer",
    component: MainContainer,
    children: [
      {
        path: "customerFiles",
        name: "CustomerFiles",
        component: () => import("../views/customer/customerFiles.vue").catch(loadCatch),
        meta: {
          cache: true,
        },
      },
      {
        path: "customerType",
        name: "CustomerType",
        component: () => import("../views/customer/customerType.vue").catch(loadCatch),
      },
      {
        path: "shieldingProducts",
        name: "ShieldingProducts",
        component: () => import("../views/customer/shieldingProducts.vue").catch(loadCatch),
      },
      {
        path: "groupManagement",
        name: "GroupManagement",
        component: () => import("../views/customer/groupManagement.vue").catch(loadCatch),
      },
      {
        path: "customerIntegral",
        name: "CustomerIntegral",
        component: () => import("../views/customer/customerIntegral.vue").catch(loadCatch),
        meta: {
          cache: true,
        },
      },
      {
        path: "customerLabel",
        name: "CustomerLabel",
        component: () => import("../views/customer/customerLabel.vue").catch(loadCatch),
      },
      {
        path: "customerLabelDetail",
        name: "CustomerLabelDetail",
        component: () => import("../views/customer/customerLabelDetail.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
      },
      {
        path: "addCustomer",
        name: "AddCustomer",
        component: () => import("../views/customer/addCustomer.vue").catch(loadCatch),
      },
      {
        path: "editCustomer",
        name: "EditCustomer",
        component: () => import("../views/customer/editCustomer.vue").catch(loadCatch),
        redirect: "editCustomer/basicInformation",
        children: [
          {
            path: "basicInformation",
            name: "BasicInformation",
            component: () => import("../views/customer/editCustomer/basicInformation.vue").catch(loadCatch),
          },
          {
            path: "commonGoods",
            name: "CommonGoods",
            component: () => import("../views/customer/editCustomer/commonGoods.vue").catch(loadCatch),
          },
          {
            path: "shieldingGoods",
            name: "ShieldingGoods",
            component: () => import("../views/customer/editCustomer/shieldingGoods.vue").catch(loadCatch),
          },
          {
            path: "customerPayment",
            name: "CustomerPayment",
            component: () => import("../views/customer/editCustomer/customerPayment.vue").catch(loadCatch),
          },
          {
            path: "subaccountManagement",
            name: "SubaccountManagement",
            component: () => import("../views/customer/editCustomer/subaccountManagement.vue").catch(loadCatch),
          },
          {
            path: "invoiceInfo",
            name: "InvoiceInfo",
            component: () => import("../views/customer/editCustomer/invoiceInfo.vue").catch(loadCatch),
          },
          {
            path: "groupMeal",
            name: "GroupMeal",
            component: () => import("../views/customer/editCustomer/groupMeal.vue").catch(loadCatch),
          },
          {
            path: "goodsAuth",
            name: "GoodsAuth",
            component: () => import("../views/customer/editCustomer/goodsAuth.vue").catch(loadCatch),
          },
        ],
      },
      {
        path: "agreementPrice",
        name: "AgreementPrice",
        component: () => import("../views/customer/agreementPrice.vue").catch(loadCatch),
      },
      {
        path: "agreementPriceInfo",
        name: "AgreementPriceInfo",
        component: () => import("../views/customer/agreementPriceInfo.vue").catch(loadCatch),
      },
      {
        path: "intelligentPrice",
        name: "IntelligentPrice",
        component: () => import("../views/customer/intelligentPrice.vue").catch(loadCatch),
      },
      {
        path: "intelligentPriceInfo",
        name: "IntelligentPriceInfo",
        component: () => import("../views/customer/intelligentPriceInfo.vue").catch(loadCatch),
      },
      {
        path: "orderSituation",
        name: "OrderSituation",
        component: () => import("../views/customer/orderSituation.vue").catch(loadCatch),
      },
      {
        path: "orderHistory",
        name: "OrderHistory",
        component: () => import("../views/customer/orderHistory.vue").catch(loadCatch),
      },
      {
        path: "favorites",
        name: "Favorites",
        component: () => import("../views/customer/favorites.vue").catch(loadCatch),
      },
      {
        path: "searchRecords",
        name: "SearchRecords",
        component: () => import("../views/customer/searchRecords.vue").catch(loadCatch),
      },
    ],
  },
  {
    path: "/reportForms",
    name: "ReportForms",
    component: MainContainer,
    children: [
      {
        path: "businessData",
        name: "BusinessData",
        component: () => import("../views/reportForms/businessData.vue").catch(loadCatch),
      },
      {
        path: "goodsSales",
        name: "GoodsSales",
        component: () => import("../views/reportForms/goodsSales/index.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
        redirect: "goodsSales/goods",
        children: [
          {
            path: "goods",
            name: "GoodsSalesGoods",
            component: () => import("../views/reportForms/goodsSales/goods.vue").catch(loadCatch),
            meta: {
              cache: true,
            },
          },
          {
            path: "classify",
            name: "GoodsSalesClassify",
            component: () => import("../views/reportForms/goodsSales/classify.vue").catch(loadCatch),
            meta: {
              cache: true,
            },
          },
        ],
      },
      {
        path: "purchaseSummary",
        name: "PurchaseSummary",
        component: () => import("../views/reportForms/purchaseSummary.vue").catch(loadCatch),
      },
      {
        path: "priceTrends",
        name: "PriceTrends",
        component: () => import("../views/reportForms/priceTrends.vue").catch(loadCatch),
      },
      {
        path: "salesProfit",
        name: "SalesProfit",
        component: () => import("../views/reportForms/salesProfit/index.vue").catch(loadCatch),
        meta: {
          cache: true,
        },
        redirect: "salesProfit/goods",
        children: [
          {
            path: "goods",
            name: "SalesProfitGoods",
            component: () => import("../views/reportForms/salesProfit/goods.vue").catch(loadCatch),
            meta: {
              cache: true,
            },
          },
          {
            path: "classify",
            name: "SalesProfitClassify",
            component: () => import("../views/reportForms/salesProfit/classify.vue").catch(loadCatch),
            meta: {
              cache: true,
            },
          },
          {
            path: "client",
            name: "SalesProfitClient",
            component: () => import("../views/reportForms/salesProfit/client.vue").catch(loadCatch),
            meta: {
              cache: true,
            },
          },
          {
            path: "salesman",
            name: "SalesProfitSalesman",
            component: () => import("../views/reportForms/salesProfit/salesman.vue").catch(loadCatch),
            meta: {
              cache: true,
            },
          },
        ],
      },
      {
        path: "loseData",
        name: "LoseData",
        component: () => import("../views/reportForms/loseData/index.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
        redirect: "loseData/goods",
        children: [
          {
            path: "goods",
            name: "LoseDataGoods",
            component: () => import("../views/reportForms/loseData/goods.vue").catch(loadCatch),
            meta: {
              cache: true,
            },
          },
          {
            path: "classify",
            name: "LoseDataClassify",
            component: () => import("../views/reportForms/loseData/classify.vue").catch(loadCatch),
            meta: {
              cache: true,
            },
          },
          {
            path: "client",
            name: "LoseDataClient",
            component: () => import("../views/reportForms/loseData/client.vue").catch(loadCatch),
            meta: {
              cache: true,
            },
          },
        ],
      },
      {
        path: "salesObjectives",
        name: "SalesObjectives",
        component: () => import("../views/reportForms/salesObjectives/index.vue").catch(loadCatch),
        meta: {
          cache: true,
        },
        redirect: "salesObjectives/total",
        children: [
          {
            path: "total",
            name: "SalesObjectivesTotal",
            component: () => import("../views/reportForms/salesObjectives/total.vue").catch(loadCatch),
            meta: {
              cache: true,
            },
          },
          {
            path: "classify",
            name: "SalesObjectivesClassify",
            component: () => import("../views/reportForms/salesObjectives/classify.vue").catch(loadCatch),
            meta: {
              cache: true,
            },
          },
          {
            path: "goods",
            name: "SalesObjectivesGoods",
            component: () => import("../views/reportForms/salesObjectives/goods.vue").catch(loadCatch),
            meta: {
              cache: true,
            },
          },
        ],
      },
      {
        path: "purchaseObjectives",
        name: "PurchaseObjectives",
        component: () => import("../views/reportForms/purchaseObjectives.vue").catch(loadCatch),
      },
    ],
  },
  {
    path: "/configurationAdmin",
    name: "ConfigurationAdmin",
    component: MainContainer,
    children: [
      {
        path: "changePassword",
        name: "ChangePassword",
        meta: {
          cache: false,
        },
        component: () => import(/* webpackChunkName: "login" */ "../views/ConfigurationAdmin/changePassword.vue").catch(loadCatch),
      },
      {
        path: "enterpriseInformation",
        name: "EnterpriseInformation",
        component: () => import("../views/ConfigurationAdmin/enterpriseInformation.vue").catch(loadCatch),
      },
      {
        path: "systemParameter",
        name: "SystemParameter",
        component: () => import("../views/ConfigurationAdmin/systemParameter.vue").catch(loadCatch),
        redirect: "systemParameter/customerSettings",
        children: [
          {
            path: "customerSettings",
            name: "CustomerSettings",
            component: () => import("../views/ConfigurationAdmin/systemParameter/customerSettings.vue").catch(loadCatch),
          },
          {
            path: "storeSettings",
            name: "StoreSettings",
            component: () => import("../views/ConfigurationAdmin/systemParameter/storeSettings.vue").catch(loadCatch),
          },
          {
            path: "businessSettings",
            name: "BusinessSettings",
            component: () => import("../views/ConfigurationAdmin/systemParameter/businessSettings.vue").catch(loadCatch),
          },
          {
            path: "paySettings",
            name: "PaySettings",
            component: () => import("../views/ConfigurationAdmin/systemParameter/paySettings.vue").catch(loadCatch),
          },
          {
            path: "smsSettings",
            name: "SmsSettings",
            component: () => import("../views/ConfigurationAdmin/systemParameter/smsSettings.vue").catch(loadCatch),
          },
          {
            path: "mapSettings",
            name: "MapSettings",
            component: () => import("../views/ConfigurationAdmin/systemParameter/mapSettings.vue").catch(loadCatch),
          },
        ],
      },
      {
        path: "payType",
        name: "PayType",
        component: () => import("../views/ConfigurationAdmin/payType.vue").catch(loadCatch),
      },
      {
        path: "applicationAddress",
        name: "ApplicationAddress",
        component: () => import("../views/ConfigurationAdmin/applicationAddress.vue").catch(loadCatch),
      },
      {
        path: "mpManagement",
        name: "MpManagement",
        component: () => import("../views/ConfigurationAdmin/mpManagement.vue").catch(loadCatch),
      },
      {
        path: "operationLog",
        name: "OperationLog",
        component: () => import("../views/ConfigurationAdmin/operationLog.vue").catch(loadCatch),
      },
      {
        path: "operator",
        name: "Operator",
        component: () => import("../views/ConfigurationAdmin/operator.vue").catch(loadCatch),
      },
      {
        path: "roleManagement",
        name: "RoleManagement",
        component: () => import("../views/ConfigurationAdmin/roleManagement.vue").catch(loadCatch),
      },
      {
        path: "printTemplate",
        name: "PrintTemplate",
        component: () => import("../views/ConfigurationAdmin/printTemplate.vue").catch(loadCatch),
      },
      {
        path: "plotConfiguration",
        name: "PlotConfiguration",
        component: () => import("../views/ConfigurationAdmin/plotConfiguration.vue").catch(loadCatch),
      },
      {
        path: "sortingBox",
        name: "SortingBox",
        component: () => import("../views/ConfigurationAdmin/sortingBox.vue").catch(loadCatch),
      },
    ],
  },
  {
    path: "/mall",
    name: "Mall",
    component: MainContainer,
    children: [
      {
        path: "store",
        name: "Store",
        component: () => import("../views/mall/store.vue").catch(loadCatch),
      },
      {
        path: "serviceTerms",
        name: "ServiceTerms",
        component: () => import("../views/mall/serviceTerms.vue").catch(loadCatch),
      },
      {
        path: "afterRules",
        name: "AfterRules",
        component: () => import("../views/mall/afterRules.vue").catch(loadCatch),
      },
      {
        path: "aboutUs",
        name: "AboutUs",
        component: () => import("../views/mall/aboutUs.vue").catch(loadCatch),
      },
      {
        path: "mallAnnouncement",
        name: "MallAnnouncement",
        component: () => import("../views/mall/mallAnnouncement.vue").catch(loadCatch),
      },
      {
        path: "addMaallAnnouncement",
        name: "AddMaallAnnouncement",
        component: () => import("../views/mall/addMaallAnnouncement.vue").catch(loadCatch),
      },
      {
        path: "indexSetting",
        name: "IndexSetting",
        component: () => import("../views/mall/indexSetting.vue").catch(loadCatch),
      },
      {
        path: "indexAlert",
        name: "IndexAlert",
        component: () => import("../views/mall/indexAlert.vue").catch(loadCatch),
      },
    ],
  },
  {
    path: "/marketing",
    name: "Marketing",
    component: MainContainer,
    children: [
      {
        path: "coupons",
        name: "Coupons",
        component: () => import(/* webpackChunkName: "warehouse" */ "../views/marketing/coupons.vue").catch(loadCatch),
      },
      {
        path: "couponsAdd",
        name: "CouponsAdd",
        meta: {
          cache: false,
        },
        component: () => import(/* webpackChunkName: "warehouse" */ "../views/marketing/couponsAdd.vue").catch(loadCatch),
      },
      {
        path: "integralActivity",
        name: "IntegralActivity",
        component: () => import(/* webpackChunkName: "warehouse" */ "../views/marketing/integralActivity.vue").catch(loadCatch),
      },
      {
        path: "integralAdd",
        name: "IntegralAdd",
        meta: {
          cache: false,
        },
        component: () => import(/* webpackChunkName: "warehouse" */ "../views/marketing/integralAdd.vue").catch(loadCatch),
      },
      {
        path: "fullReduceFullGift",
        name: "FullReduceFullGift",
        component: () => import(/* webpackChunkName: "warehouse" */ "../views/marketing/fullReduceFullGift.vue").catch(loadCatch),
      },
      {
        path: "fullReduceAdd",
        name: "FullReduceAdd",
        meta: {
          cache: false,
        },
        component: () => import(/* webpackChunkName: "warehouse" */ "../views/marketing/fullReduceAdd.vue").catch(loadCatch),
      },
      {
        path: "flashSale",
        name: "FlashSale",
        component: () => import(/* webpackChunkName: "warehouse" */ "../views/marketing/flashSale.vue").catch(loadCatch),
      },
      {
        path: "flashSaleAdd",
        name: "FlashSaleAdd",
        meta: {
          cache: false,
        },
        component: () => import(/* webpackChunkName: "warehouse" */ "../views/marketing/flashSaleAdd.vue").catch(loadCatch),
      },
      {
        path: "goodsLimitation",
        name: "GoodsLimitation",
        component: () => import("../views/marketing/goodsLimitation.vue").catch(loadCatch),
      },
      {
        path: "goodsLimitationAdd",
        name: "GoodsLimitationAdd",
        meta: {
          cache: false,
        },
        component: () => import(/* webpackChunkName: "warehouse" */ "../views/marketing/goodsLimitationAdd.vue").catch(loadCatch),
      },
      {
        path: "goodsPresell",
        name: "GoodsPresell",
        component: () => import("../views/marketing/goodsPresell.vue").catch(loadCatch),
      },
    ],
  },
  {
    path: "/finance",
    name: "Finance",
    component: MainContainer,
    children: [
      {
        path: "customersSettlement",
        name: "CustomersSettlement",
        component: () => import("../views/finance/customersSettlement.vue").catch(loadCatch),
        meta: {
          cache: true,
        },
      },
      {
        path: "salesSettlementDetails",
        name: "SalesSettlementDetails",
        component: () => import("../views/finance/salesSettlementDetails.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
      },
      {
        path: "finalStatement",
        name: "FinalStatement",
        component: () => import("../views/finance/finalStatement.vue").catch(loadCatch),
        meta: {
          cache: true,
        },
      },
      {
        path: "customerBalance",
        name: "CustomerBalance",
        component: () => import("../views/finance/customerBalance.vue").catch(loadCatch),
        meta: {
          cache: true,
        },
      },
      // {
      //   path: "procurementAccount",
      //   name: "ProcurementAccount",
      //   component: () => import("../views/finance/procurementAccount.vue").catch(loadCatch),
      //   meta: {
      //     cache: true,
      //   },
      // },      
      {
        path: "procurementSettlement",
        name: "ProcurementSettlement",
        component: () => import("../views/finance/procurementSettlement.vue").catch(loadCatch),
        meta: {
          cache: true,
        },
      },
      {
        path: "batchReconciliation",
        name: "BatchReconciliation",
        component: () => import("../views/finance/batchReconciliation.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
      },
      {
        path: "purchaseStatement",
        name: "PurchaseStatement",
        meta: {
          cache: false,
        },
        component: () => import("../views/finance/purchaseStatement.vue").catch(loadCatch),
      },
      {
        path: "purchaseStatementDetail",
        name: "PurchaseStatementDetail",
        meta: {
          cache: false,
        },
        component: () => import("../views/finance/purchaseStatementDetail.vue").catch(loadCatch),
      },
      {
        path: "prepayBalance",
        name: "PrepayBalance",
        meta: {
          cache: false,
        },
        component: () => import("../views/finance/prepayBalance.vue").catch(loadCatch),
      },      
      {
        path: "purchaseAccounts",
        name: "PurchaseAccounts",
        component: () => import("../views/finance/purchaseAccounts.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
        redirect: "/finance/purchaseAccounts/summary",
        children: [
          {
            path: "summary",
            name: "purchaseAccountsSummary",
            component: () => import("../views/finance/purchaseAccounts/summary.vue").catch(loadCatch),
            meta: {
              cache: true,
            },
          },
          {
            path: "records",
            name: "purchaseAccountsRecords",
            component: () => import("../views/finance/purchaseAccounts/records.vue").catch(loadCatch),
            meta: {
              cache: true,
            },
          },
        ],
      },
      {
        path: "finalStatementDetail",
        name: "FinalStatementDetail",
        meta: {
          cache: false,
        },
        component: () => import("../views/finance/finalStatementDetail.vue").catch(loadCatch),
      },
      {
        path: "procurementReconciliation",
        name: "ProcurementReconciliation",
        meta: {
          cache: false,
        },
        component: () => import("../views/finance/procurementSettlement/procurementReconciliation.vue").catch(loadCatch),
      },
      {
        path: "harvestDetail",
        name: "HarvestDetail",
        meta: {
          cache: false,
        },
        component: () => import("../views/finance/procurementSettlement/harvestDetail.vue").catch(loadCatch),
      },
      {
        path: "returnDetails",
        name: "ReturnDetails",
        meta: {
          cache: false,
        },
        component: () => import("../views/finance/procurementSettlement/returnDetails.vue").catch(loadCatch),
      },
      {
        path: "platformSettlement",
        name: "PlatformSettlement",
        meta: {
          cache: true,
        },
        component: () => import("../views/finance/platformSettlement.vue").catch(loadCatch),
      },
      {
        path: "settlement",
        name: "Settlement",
        meta: {
          cache: false,
        },
        component: () => import("../views/finance/settlement.vue").catch(loadCatch),
      },
      {
        path: "settlementSettings",
        name: "SettlementSettings",
        meta: {
          cache: false,
        },
        component: () => import("../views/finance/settlementSettings/settlementSettings.vue").catch(loadCatch),
      },
      {
        path: "paymentDetails",
        name: "PaymentDetails",
        meta: {
          cache: false,
        },
        component: () => import("../views/finance/paymentDetails.vue").catch(loadCatch),
      },
      {
        path: "accountPeriodStatistics",
        name: "AccountPeriodStatistics",
        meta: {
          cache: false,
        },
        component: () => import("../views/finance/accountPeriodStatistics.vue").catch(loadCatch),
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "login" */ "../views/login/login.vue").catch(loadCatch),
  },
  {
    path: "/crm",
    name: "Crm",
    component: MainContainer,
    children: [
      {
        path: "staffManagement",
        name: "StaffManagement",
        component: () => import("../views/crm/staffManagement/index.vue").catch(loadCatch),
        redirect: "staffManagement/salesman",
        children: [
          {
            path: "salesman",
            name: "Salesman",
            component: () => import("../views/crm/staffManagement/salesman.vue").catch(loadCatch),
          },
          {
            path: "team",
            name: "Team",
            component: () => import("../views/crm/staffManagement/team.vue").catch(loadCatch),
          },
        ],
      },
      {
        path: "levelManagement",
        name: "LevelManagement",
        meta: {
          cache: false,
        },
        component: () => import("../views/crm/levelManagement.vue").catch(loadCatch),
      },
      {
        path: "businessManagementAdd",
        name: "BusinessManagementAdd",
        meta: {
          cache: false,
        },
        component: () => import("../views/crm/businessManagementAdd.vue").catch(loadCatch),
      },
      {
        path: "teamAdd",
        name: "TeamAdd",
        meta: {
          cache: false,
        },
        component: () => import("../views/crm/teamAdd.vue").catch(loadCatch),
      },
      {
        path: "businessPerformance",
        name: "BusinessPerformance",
        component: () => import("../views/crm/businessPerformance.vue").catch(loadCatch),
      },
      {
        path: "visitInfo",
        name: "VisitInfo",
        component: () => import("../views/crm/visitInfo.vue").catch(loadCatch),
      },
      {
        path: "taskManagement",
        name: "TaskManagement",
        component: () => import("../views/crm/taskManagement.vue").catch(loadCatch),
      },
      {
        path: "taskManagementAdd",
        name: "TaskManagementAdd",
        meta: {
          cache: false,
        },
        component: () => import("../views/crm/taskManagementAdd.vue").catch(loadCatch),
      },
      {
        path: "commissionSetting",
        name: "CommissionSetting",
        meta: {
          cache: false,
        },
        component: () => import("../views/crm/commissionSetting.vue").catch(loadCatch),
      },
      {
        path: "clientSalesman",
        name: "ClientSalesman",
        meta: {
          cache: false,
        },
        component: () => import("../views/crm/clientSalesman.vue").catch(loadCatch),
      },
      {
        path: "commissionStatistics",
        name: "CommissionStatistics",
        meta: {
          cache: false,
        },
        component: () => import("../views/crm/commissionStatistics.vue").catch(loadCatch),
      },
      {
        path: "priceAdjustmentRule",
        name: "PriceAdjustmentRule",
        component: () => import("../views/crm/priceAdjustmentRule.vue").catch(loadCatch),
      },
      {
        path: "priceAdjustmentRecord",
        name: "PriceAdjustmentRecord",
        component: () => import("../views/crm/priceAdjustmentRecord.vue").catch(loadCatch),
      },
    ],
  },
  {
    path: "/supplier",
    name: "Supplier",
    component: MainContainer,
    children: [
      {
        component: () => import(/* webpackChunkName: "login" */ "../views/supplier/deliveryNote.vue").catch(loadCatch),
        path: "deliveryNote",
        name: "DeliveryNote",
      },
      {
        component: () => import(/* webpackChunkName: "login" */ "../views/supplier/sortingPrint.vue").catch(loadCatch),
        path: "sortingPrint",
        name: "SortingPrint",
      },
      {
        path: "supplierGoods",
        name: "SupplierGoods",
        component: () => import(/* webpackChunkName: "goods" */ "../views/supplier/supplierGoods.vue").catch(loadCatch),
        meta: {
          cache: false,
        },
        redirect: "/supplierGoods/goodsFiles",
        children: [
          {
            path: "supplierGoodsFiles",
            name: "SupplierGoodsFiles",
            component: () => import("../views/supplier/supplierGoodsFiles.vue").catch(loadCatch),
            meta: {
              cache: true,
            },
          },
          {
            path: "supplierGoodsEditHistory",
            name: "SupplierGoodsEditHistory",
            component: () => import("../views/supplier/supplierGoodsEditHistory.vue").catch(loadCatch),
            meta: {
              cache: true,
            },
          },
        ],
      },
      // {
      //   component: () => import(/* webpackChunkName: "login" */ "../views/supplier/classifyList.vue").catch(loadCatch),
      //   path: "classifyList",
      //   name: "ClassifyList",
      // },
      {
        component: () => import(/* webpackChunkName: "login" */ "../views/supplier/classifyDetail.vue").catch(loadCatch),
        path: "classifyDetail",
        name: "ClassifyDetail",
      },
      {
        component: () => import(/* webpackChunkName: "login" */ "../views/supplier/printerConfiguration.vue").catch(loadCatch),
        path: "printerConfiguration",
        name: "PrinterConfiguration",
      },
    ],
  },
  // {
  //   path: '/findPassword',
  //   name: 'FindPassword',
  //   component: () => import(/* webpackChunkName: "login" */ '../views/login/findPassword.vue').catch(loadCatch),
  // },

  {
    path: "*",
    name: "ErrorPage",
    component:ErrorPage
    //redirect: { name: "AdminIndex" },
  },
];

function deepBuildMenu(routeList) {
  return routeList
    .map((routeItem) => {
      const menuItem = {
        ...routeItem,
        component: undefined,
      };
      if (routeItem.children && routeItem.children.length > 0) {
        menuItem.children = deepBuildMenu(menuItem.children);
      }

      return menuItem;
    })
    .filter((item) => !["/", "*"].includes(item.path) && item.show !== false);
}

function deepfindMenu(routeList, path, pathList) {
  return routeList.find((routeItem) => {
    const menuItem = {
      path: routeItem.path,
      title: routeItem.title,
      icon: routeItem.icon,
    };
    if (routeItem.children && routeItem.children.length > 0) {
      const end = deepfindMenu(routeItem.children, path, pathList);
      if (end) {
        pathList.push(menuItem);
      }
      return Boolean(end);
    }
    if (routeItem.path === path) {
      pathList.push(menuItem);
    }

    return routeItem.path === path;
  });
}

export const getRouteMenu = () => deepBuildMenu(routes.find((item) => item.path === "/main").children);

export const getRouteByPath = (path) => {
  const routePath = [];
  deepfindMenu(routes.find((item) => item.path === "/main").children, path, routePath);
  return routePath.reverse();
};

export default routes;
