export const UPLOAD_DOMAIN = process.env.VUE_APP_UPLOAD_DOMAIN;

export const getBaseUrl = () => {
  if (process.env.VUE_APP_ENV === 'production') {
    // 线上环境根据当前的域名来构建
    const host = window.location.host;
    if (host.includes('.sandbox')) {
      const originList = host.split('.');
      originList.splice(0, 1, `${originList[0]}-api`);
      return `${window.location.protocol}//${originList.join('.')}`;
    } else {
      return 'https://sxb-api.shixbao.com'
    }
  } else {
    // 本地和测试环境 使用环境变量中的 配置
    return process.env.VUE_APP_API_DOMAIN;
  }
};

export const getPrintBaseUrl = () => {
  if (process.env.VUE_APP_ENV === 'production') {
    // 线上环境根据当前的域名来构建
    return `${window.location.origin}/printer`;
  } else {
    // 本地和测试环境 使用环境变量中的 配置
    return process.env.VUE_APP_PRINT_DOMAIN;
  }
};

export const getDomainUrl = () => {
  if (process.env.VUE_APP_ENV === 'production') {
    // 线上环境根据当前的域名来构建
    return window.location.origin;
  } else {
    // 本地和测试环境 使用环境变量中的 配置
    return process.env.VUE_APP_DOMAIN;
  }
};
