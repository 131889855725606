import Vue from 'vue';
import { initLogReport,get } from '@smart/util-web';
import './global/globalBootstrap';
import './style/index.scss';
import App from './App.vue';
import router from './router';
import store from './store';
import './plugins/element';
import globalMethods from './global/globalMethods';
import gloablMixin from './global/gloablMixin';
import globalComponents from './global/globalComponents';
import FixedTemplate from "@/components/common/FixedTemplate.vue";
// import { getPrintPageHtml } from './global/printNative';

// getPrintPageHtml();

initLogReport(Vue, {
  target: 'joint-food-admin',
});
Vue.prototype.$ELEMENT = { size: 'medium' };
Vue.prototype.$GET = get;
Vue.config.productionTip = false;
if (process.env.NODE_ENV === 'development') {
  Vue.config.devtools = true;
  Vue.config.performance = true;
}
const query = new URL(location.href).searchParams
const queryTenantMark = query.get('tenantMark')
if (queryTenantMark) {
  const tenantMark = localStorage.getItem('tenantMark')
  const printConfiguration = localStorage.getItem('printConfiguration')
  if (tenantMark&&printConfiguration&&tenantMark!=queryTenantMark) {
    localStorage.setItem(`printConfiguration_${tenantMark}`, printConfiguration)
    localStorage.removeItem('printConfiguration')
  }
  if (localStorage.getItem(`printConfiguration_${queryTenantMark}`)) {
    localStorage.setItem('printConfiguration', localStorage.getItem(`printConfiguration_${queryTenantMark}`))
  }
  localStorage.setItem('tenantMark', queryTenantMark)
}
function setPageFavicon(iconPath) {
  const link = document.createElement('link');
  link.rel = 'icon';
  link.href = iconPath;
  document.head.appendChild(link);
}
if (localStorage.getItem('tenantMark') ) {
  const faviconPath = `https://sxsh.oss-cn-hangzhou.aliyuncs.com/public/joint_food/admin/tenantMark/${localStorage.getItem('tenantMark')}/favicon.ico`;
  setPageFavicon(faviconPath);
}

Vue.use(globalMethods);

Vue.use(globalComponents);

Vue.component('FixedTemplate', FixedTemplate)

Vue.mixin(gloablMixin);


let vue = new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');

export default vue;
