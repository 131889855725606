const isEmpty = (value) => (value === null || value === undefined || value === '')

export default [
  {
    // 校验必填
    name: 'require',
    validateHandler(value) {
      if (isEmpty(value)) {
        return '必填';
      }
      return true;
    },
  },
  {
    // 校验是一个身份证号
    name: 'idcard',
    validateHandler(value) {
      if (isEmpty(value)) return true;
      const valueStr = value.toString();
      if (/^[0-9]{17}[0-9xX]$/.test(valueStr)) {
        return true;
      }
      return '不是一个身份证号';
    },
  },
  {
    // 校验是一个电话号码
    name: 'phone',
    validateHandler(value) {
      if (isEmpty(value)) return true;
      const valueStr = value.toString();
      if (/^[01][0-9]{9,10}$/.test(valueStr)) {
        return true;
      }
      return '不是一个电话号码';
    },
  },
  {
    // 校验是一个电话号码
    name: 'telephone',
    validateHandler(value) {
      if (isEmpty(value)) return true;
      const valueStr = value.toString();
      if (/^[1][3-9]\d{9}$/.test(valueStr)) {
        return true;
      }
      return '错误！请输入正确手机号';
    },
  },
  {
    // 校验是一个字符或数字
    name: 'letterOrNum',
    validateHandler(value) {
      if (isEmpty(value)) return true;
      const valueStr = value.toString();
      if (/^[0-9a-zA-Z]+$/.test(valueStr)) {
        return true;
      }
      return '必须是字母或数字';
    },
  },
  {
    // 校验是一个数字
    name: 'num',
    validateHandler(value) {
      if (isEmpty(value)) return true;
      const valueStr = value.toString();
      if (/^([0-9.]|-)+$/.test(valueStr)) {
        return true;
      }
      return '必须是一个数字';
    },
  },
  {
    name: 'integer',
    validateHandler(value) {
      if (isEmpty(value)) return true;
      const valueStr = value.toString();
      if (/^\d+$/.test(valueStr)) {
        return true;
      }
      return '必须是一个整数';
    },
  },
  {
    // 校验最大值
    name: 'max',
    validateHandler(value, param) {
      if (isEmpty(value)) return true;
      const maxNum = param[0];
      if (+value > +maxNum) {
        return `不得大于${maxNum}`;
      }
      return true;
    },
  },
  {
    // 校验最小值
    name: 'min',
    validateHandler(value, param) {
      if (isEmpty(value)) return true;
      const maxNum = param[0];
      if (+value < +maxNum) {
        return `不得小于${maxNum}`;
      }
      return true;
    },
  },
  {
    // 校验大于最小值
    name: 'more',
    validateHandler(value, param) {
      if (isEmpty(value)) return true;
      const maxNum = param[0];
      if (+value > +maxNum) {
        return true;
      }
      return `必须大于${maxNum}`;
    },
  },
  {
    // 验证某个字段的值是否在某个范围 in:1:2:3
    name: 'in',
    validateHandler(value, param) {
      if (isEmpty(value)) return true;
      if (param.includes(`${value}`)) {
        return true;
      }
      return '不允许的数据值';
    },
  },
  {
    // 验证某个字段的值不在某个范围 notIn:1:2:3
    name: 'notIn',
    validateHandler(value, param) {
      if (isEmpty(value)) return true;
      if (param.includes(`${value}`)) {
        return '不允许的数据值';
      }
      return true;
    },
  },
  {
    // 验证某个字段的值是否在某个区间 between:1:5
    name: 'between',
    validateHandler(value, param) {
      if (isEmpty(value)) return true;
      if ((+value > +param[0]) && (+value < +param[1])) {
        return true;
      }
      return `必须在${param[0]}-${param[1]}之间`;
    },
  },
  {
    // 验证某个字段的值的长度是否在某个范围 length:1:5
    name: 'length',
    validateHandler(value, param) {
      if (isEmpty(value)) return true;
      if (param.length === 1) {
        if (value.length === +param[0]) {
          return true;
        }
        return `长度必须为${param[0]}位`;
      }
      const valueStr = value.toString();
      if (valueStr.length >= param[0] && valueStr.length <= param[1]) {
        return true;
      }
      return `长度必须是${param[0]}-${param[1]}位`;
    },
  },
  {
    // 校验最大长度 不得超过 maxLen:30
    name: 'maxLen',
    validateHandler(value, param) {
      if (isEmpty(value)) return true;
      const valueStr = value.toString();
      if (valueStr.length <= param) {
        return true;
      }
      return `最多${maxLen}位`;
    },
  },
  {
    // 支持直接使用正则验证 regex:\d{6} 注意 不得出现|
    name: 'regex',
    validateHandler(value, param) {
      if (isEmpty(value)) return true;
      const regexp = new RegExp(param[0], 'g');
      if (regexp.test(value)) {
        return true;
      }
      return '参数错误';
    },
  },
];
