<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { AMapUtils } from "@smart/util-web";
import { $sp_querySysConfigByKey } from "@/api/configurationAdmin";
export default {
  name: "app",
  components: {},
  data() {
    return {};
  },
  created() {
    const amap = new AMapUtils()
    const getCenterpoint = (coordinates) => {
      const [point1, point2] = coordinates.split(';').map(coord => coord.split(',').map(parseFloat));
      const [lon1, lat1] = point1;
      const [lon2, lat2] = point2;
      const decimalLength = (num) => {
        const parts = num.toString().split('.');
        return parts[1] ? parts[1].length : 0;
      };
      const lonDecimalLength = Math.max(decimalLength(lon1), decimalLength(lon2));
      const latDecimalLength = Math.max(decimalLength(lat1), decimalLength(lat2));
      const dLon = (lon2 - lon1) * Math.PI / 180;
      const lat1Rad = lat1 * Math.PI / 180;
      const lat2Rad = lat2 * Math.PI / 180;
      const lon1Rad = lon1 * Math.PI / 180;
      const Bx = Math.cos(lat2Rad) * Math.cos(dLon);
      const By = Math.cos(lat2Rad) * Math.sin(dLon);
      const lat3 = Math.atan2(Math.sin(lat1Rad) + Math.sin(lat2Rad), Math.sqrt((Math.cos(lat1Rad) + Bx) * (Math.cos(lat1Rad) + Bx) + By * By));
      const lon3 = lon1Rad + Math.atan2(By, Math.cos(lat1Rad) + Bx);
      const roundedLat = lat3 * 180 / Math.PI;
      const roundedLon = lon3 * 180 / Math.PI;
      return [roundedLon.toFixed(lonDecimalLength), roundedLat.toFixed(latDecimalLength)];
    }
    amap.getCity().then(city=>{
      const center = getCenterpoint(city.rectangle)
      localStorage.setItem('mapCenter', center)
    })
    $sp_querySysConfigByKey({ dataKey: "EnterpriseInfoBO" }).then((res) => {
      const data = res ? JSON.parse(res) : {}
      this.$store.commit("setSystemCfg", data);
      document.title = data.projectName
    }).catch(err=>{
      this.$store.commit("setSystemCfg", {
        msg: err.msg
      });
      document.title = '登录'
    })
  },
};
</script>

<style>
body,
html {
  width: 100%;
  height: 100%;
}
p,
body,
html {
  margin: 0;
  padding: 0;
}
body {
  background-color: #f5f5f5;
  /* background-color: red; */
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
</style>
