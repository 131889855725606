<template>
  <!-- 菜单栏 -->
  <div class="menu-bar-box">
    <ul>
      <li class="task" @click="getTaskList">
        任务
      </li>
    </ul>
    <!-- 任务列表 -->
    <el-drawer :size="420" :append-to-body="true" :visible.sync="drawer" :with-header="false">
      <div class="task-content">
        <p class="task-list-title">任务列表</p>
        <ul class="task-list-data" v-loading="taskLoading" element-loading-text="加载中...">
          <li class="task-item fc" v-for="item in taskList" :key="item.id">
            <el-tag effect="plain">
              导出
            </el-tag>
            <div class="task-info">
              <p class="fc">
                <el-tooltip :content="item.taskTitle" placement="top-end" popper-class="task-tip">
                  <span class="task-name">{{item.taskTitle}}</span>
                </el-tooltip>
                <el-tag size="mini" effect="plain" :type="taskStatus[item.status].type">{{taskStatus[item.status].label}}</el-tag>
              </p>
              <p class="task-createtime">{{item.createTime}} 
                <span :class="[item.downloadStatus!=1?'hasDown':'']">
                  {{item.downloadStatus==1?'已下载':'未下载'}}</span></p>
            </div>
            <i class="el-icon-download" 
            :class="[item.status!=1?'exportSuccess':'',item.downloadStatus!=1?'hasDown':'']" @click="downLoadFile(item)"></i>
          </li>
        </ul>
        <p class="clear-task" @click="clearTaskList">清空全部任务</p>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { $sg_userTaskQuery, $sp_clearUserTask, $sp_updateDownloadStatus } from '@/api/task';
import { exportFileSync } from '@smart/util-web';
export default {
  data() {
    return {
      drawer: false,
      taskList: [],
      taskLoading: false,
      taskStatus: {
        0: { label: '导出中...', type: 'info' },
        1: { label: '导出成功', type: '' },
        2: { label: '导出失败', type: 'danger' }
      }
    };
  },
  methods: {
    // 查看任务列表
    getTaskList() {
      this.drawer = true;
      this.queryTaskData();
    },
    // 获取任务列表
    queryTaskData() {
      this.taskLoading = true;
      $sg_userTaskQuery().then((d) => {
        this.taskList = d || [];
      }).finally(() => {
        this.taskLoading = false;
      })
    },
    // 清空任务
    clearTaskList() {
      if (!this.taskList.length) {
        this.$message.error('暂无可清空的任务');
        return;
      }
      this.$confirm('确定清空所有任务?', '提示', {
        type: 'warning'
      }).then(() => {
        $sp_clearUserTask({}).then(() => {
          this.taskList = [];
        })
      }).catch(() => {})
    },
    // 下载文件
    downLoadFile(item) {
      if (item.status !== 1) {
        return;
      }
      if (!item.path) {
        this.$message.error('下载地址不能为空');
        return;
      }
      const filePath = this.$imgPrefix(item.path);
      exportFileSync(filePath, {}, 'get', item.fileName).then(() => {
        this.$message.success('下载成功');
        $sp_updateDownloadStatus({id:item.id}).then(()=>{
          this.queryTaskData();
        })
      });
    }
  },
};
</script>

<style>
.el-tooltip__popper.task-tip {
  max-width: 300px;
}
</style>
<style scoped lang="scss">
.fc {
  display: flex;
  align-items: center;
}
.hasDown{
  color: #67C23A!important;
  cursor: pointer;
}
.menu-bar-box {
  position: fixed;
  bottom: 40px;
  right: 0;
  z-index: 300;
  .task {
    width: 36px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    background: rgba(31, 36, 46, 0.8);
    border-radius: 2px 0 0 2px;
    font-weight: 500;
    color: #fff;
    font-size: 12px;
    &:hover {
      background: $themeColor;
    }
  }
}
.task-content {
  height: 100%;
  overflow: hidden;
  .task-list-title {
    width: 420px;
    height: 44px;
    line-height: 34px;
    background: $themeColor;
    color: #fff;
    box-sizing: border-box;
    padding: 5px 10px;
  }
  .task-list-data {
    height: calc(100% - 111px);
    overflow: auto;
    box-sizing: border-box;
    padding: 10px;
  }
  .task-item {
    margin-bottom: 15px;
    .task-info {
      flex: 1;
      color: #303030;
      font-size: 14px;
      box-sizing: border-box;
      padding: 0 10px;
      .task-name {
        display: inline-block;
        max-width: calc(310px - 60px);
        box-sizing: border-box;
        padding-right: 10px;
        word-wrap: break-word; /*强制换行*/
        overflow: hidden; /*超出隐藏*/
        text-overflow: ellipsis; /*隐藏后添加省略号*/
        white-space: nowrap; /*强制不换行*/
      }
      .task-createtime {
        margin-top: 4px;
        font-size: 12px;
        color: #aeaeae;
      }
    }
    .el-icon-download {
      font-size: 24px;
      color: #aeaeae;
      cursor: pointer;
    }
    .exportSuccess {
      
      cursor: not-allowed;
      color: #303030;
      &:hover {
        color: $themeColor;
      }
    }
  }
  .clear-task {
    width: 420px;
    height: 66px;
    border-top: 1px solid #f3f3f3;
    line-height: 66px;
    text-align: center;
    background: #fff;
    font-size: 14px;
    color: #303030;
    cursor: pointer;
  }
}
</style>
