import api from "./api";
//根据key查系统配置，强调：该接口只能erp后台调用
export const $sp_querySysConfigByKey = (params) => {
  let p = params
  p.tenantMark = localStorage.getItem('tenantMark')?localStorage.getItem('tenantMark'):''
  return api.postNoMessage("/sssk/erp/api/platform/biz/sysConfigResource/querySysConfigByKey", p)
}
  ;
//更新配置
export const $sp_updateByKey = (params) => api.postNoMessage("/sssk/erp/api/platform/biz/sysConfigResource/updateByKey", params);

//支付方式
//查询支付配置
export const $sp_queryPayConfigList = (params) => api.postNoMessage("/sssk/erp/api/platform/biz/sysPayConfig/queryPayConfigList", params);
//新增或更新支付配置
export const $sp_addOrUpdatePayConfig = (params) => api.post("/sssk/erp/api/platform/biz/sysPayConfig/updatePayConfigState", params);
//新增支付配置
export const $sp_addPayConfig = (params) => api.post("/sssk/erp/api/platform/biz/sysPayConfig/addPayConfig", params);

//查询短信和地图配置
export const $sp_queryTenantUnionConfig = (params) => api.getNoMessage("/sssk/erp/api/platform/biz/tenantUnionConfig/getInfo", params);
//更新短信和地图配置配置
export const $sp_updateSmsConfigAndMapConfig = (params) => api.post("/sssk/erp/api/platform/biz/tenantUnionConfig/smsConfigAndMapConfig", params);

//分拣框管理
//分拣框分页查询
export const $sp_packBasketPageQuery = (params) =>
  api.postNoMessage("/sssk/erp/api/platform/biz/sysPackBasket/packBasketPageQuery", params);
//新增或更新分拣框
export const $sp_addOrUpdatePackBasket = (params) => api.post("/sssk/erp/api/platform/biz/sysPackBasket/addOrUpdatePackBasket", params);
//分拣框删除
export const $sp_deletePackBasket = (params) => api.post("/sssk/erp/api/platform/biz/sysPackBasket/deletePackBasket", params);

//角色管理
//角色管理-所有菜单
export const $sp_queryMenuTree = (params) => api.postNoMessage("/sssk/erp/api/platform/biz/auth/menu/queryMenuTree", params);
//角色管理-角色分页列表
export const $sp_queryRoleManagementList = (params) => api.postNoMessage("/sssk/erp/api/platform/biz/conf/page", params);
//角色管理-创建角色
export const $sp_createRoleManagement = (params) => api.post("/sssk/erp/api/platform/biz/conf/create", params);
//角色管理-查看角色详情
export const $sg_getRoleManagementDetail = (params) => api.getNoMessage("/sssk/erp/api/platform/biz/conf/info", params);
//角色管理-删除角色
export const $sg_delRoleManagement = (params) => api.get("/sssk/erp/api/platform/biz/conf/update/state", params);
//角色管理-修改角色
export const $sp_editRoleManagement = (params) => api.post("/sssk/erp/api/platform/biz/conf/update", params);

//分页查询单据操作历史
export const $sp_queryRecordByPage = (params) => api.postNoMessage("/sssk/erp/api/platform/biz/operateRecord/queryRecordByPage", params);

//根据打印配置类型的简单信息查询
export const $sp_sysConfigByTypeSimpleQuery = (params) =>
  api.postNoMessage("/sssk/erp/api/platform/biz/sysPrintPayConfig/sysConfigByTypeSimpleQuery", params);
//根据打印配置类型分页查询
export const $sp_sysPrintConfigByTypePageQuery = (params) =>
  api.postNoMessage("/sssk/erp/api/platform/biz/sysPrintPayConfig/sysPrintConfigByTypePageQuery", params);
//修改打印配置
export const $sp_updateSysPrintConfig = (params) => api.post("/sssk/erp/api/platform/biz/sysPrintPayConfig/updateSysPrintConfig", params);
//新增-修改
export const $sp_addOrUpdateShopSetting = (params) => api.postNoTips("/sssk/erp/api/platform/biz/main/setting/addOrUpdate", params);
// 新增编辑计量单位 商品标签
export const $sp_addOrUpdateSysDictItem = (params) => api.postNoTips('/sssk/erp/api/platform/biz/sysDict/addOrUpdateSysDictItem', params);
//回显
export const $sp_shopSettingInfo = (params) => api.get("/sssk/erp/api/platform/biz/main/setting/info", params);
//商城首页查询商品列表
export const $sp_querySpecList = (params) => api.postNoTips("/sssk/erp/api/platform/biz/goodsInfo/queryGoodsInfoList", params);


//获取绑定url
export const $sp_getBindOpenAccountUrl = (params) => api.getNoMessage("/sssk/erp/api/platform/biz/wx/miniProgram/bindOpenAccount/authCode", params);
//获取绑定小程序信息
export const $sp_getBindMpInfo = (params) => api.getNoMessage("/sssk/erp/api/platform/biz/wx/miniProgram/account/baseInfo", params);
//获取小程序模板列表
export const $sp_getMpTemplateList = (params) => api.postNoMessage("/sssk/erp/api/platform/biz/wx/miniProgram/template/list", params);
//设置小程序模板并选为体验版
export const $sp_setMpTemplate = (params) => api.postNoMessage("/sssk/erp/api/platform/biz/wx/miniProgram/commit", params);
//小程序提交审核
export const $sp_mpAuditSubmit = (params) => api.post("/sssk/erp/api/platform/biz/wx/miniProgram/submitAudit", params);
//查询小程序审核状态
export const $sp_getMpAuditStatus = (params) => api.getNoMessage("/sssk/erp/api/platform/biz/wx/miniProgram/auditStatus", params);
//小程序已审核代码发布 
export const $sp_mpAuditPublish = (params) => api.getNoMessage("/sssk/erp/api/platform/biz/wx/miniProgram/release", params);
//小程序代码回退
export const $sp_mpRollback = (params) => api.post("/sssk/erp/api/platform/biz/wx/miniProgram/revertCodeRelease", params);
//小程序操作日志
export const $sp_getMpOptLog = (params) => api.postNoMessage("/sssk/erp/api/platform/biz/wx/miniProgram/log/list", params);