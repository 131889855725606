import PageTitle from '@/components/common/PageTitle.vue';
import TableEmpty from '@/components/common/TableEmpty.vue';
import EmptyData from '@/components/common/EmptyData.vue';

export default {
  install(Vue) {
    Vue.component(PageTitle.name, PageTitle);
    Vue.component(TableEmpty.name, TableEmpty);
    Vue.component(EmptyData.name, EmptyData);
  },
};
