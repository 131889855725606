<template>
  <div class="smart-form-render">
    <div class="form-container">
      <div v-for="(field, index) in renderFieldList" :key="field.id" class="col-block" :class="`span-${field.span} form-item-${field.key} ${showMore||index<lineMax||(field.widget == 'btns'||field.slotName == 'handleSlot')?'show':'hide'}`" >
        <!-- 自定义label -->
        <template v-if="field.slotLabel">
          <slot :name="field.slotLabel" :field="field" :params="params"></slot>
        </template>
        <div class="form-label" v-else-if="field.label" :style="{width: labelWidth}">{{field.label}}</div>
          <el-cascader
            v-if="field.widget == 'cascader'"
            v-model="params[field.key]"
            :placeholder="field.placeholder"
            :options="field.optionsData" v-bind="field.options" v-on="field.handle" filterable></el-cascader>
          <template v-else-if="field.widget == 'slot'">
            <slot :name="field.slotName" :field="field" :params="params"></slot>
            <el-button v-if="renderFieldList.length > lineMax && field.slotName == 'handleSlot'" type="text" @click="toggleMore">
              <span> {{ showMore ? "收起" : "高级搜索" }}</span>
              <i :class="`el-icon-arrow-${showMore?'up':'down'}`"></i>
            </el-button>
          </template>
          <el-select v-else-if="field.widget == 'select'"
            v-model="params[field.key]"
            v-bind="field.options"
            v-on="field.handle"
            @change="submitForm"
            :placeholder="field.placeholder" filterable>
              <el-option v-for="(option, pIndex) in field.optionsData"
                :key="pIndex"
                :label="option.label"
                :value="option.value"
                ></el-option>
          </el-select>
          <el-radio-group v-else-if="field.widget == 'radio'" v-model="params[field.key]">
            <el-radio v-for="(option, pIndex) in field.optionsData"
              :key="pIndex"
              :label="option.value">{{option.label}}</el-radio>
          </el-radio-group>
          <el-checkbox-group v-else-if="field.widget == 'checkbox'" v-model="params[field.key]">
            <el-checkbox v-for="(option, pIndex) in field.optionsData"
              :key="pIndex"
              :label="option.value">{{option.label}}</el-checkbox>
          </el-checkbox-group>
          <InputRange  v-else-if="field.widget == 'inputRange'" v-model="params[field.key]" :option="field.options"/>
          <el-date-picker
            v-else-if="field.widget == 'daterange'"
            v-model="params[field.key]"
            type="daterange"
            @change="submitForm"
            v-bind="field.options"></el-date-picker>
          <el-date-picker
            v-else-if="field.widget == 'datetimerange'"
            v-model="params[field.key]"
            type="datetimerange"
            @change="submitForm"
            v-bind="field.options"></el-date-picker>
          <el-date-picker
            v-else-if="field.widget == 'date'"
            v-model="params[field.key]"
            type="date"
            @change="submitForm"
            v-bind="field.options">
          </el-date-picker>
          <div v-else-if="field.widget == 'btns'" :key="field.id" class="form-item-btns">
            <el-button v-for="(option, pIndex) in field.optionsData"
              :key="pIndex" :type="option.theme"
              v-bind="option.options"
              @click="$emit(option.emit, option)">{{option.label}}</el-button>
            <el-button v-if="renderFieldList.length > (lineMax+1)" type="text" @click="toggleMore">
              <span> {{ showMore ? "收起" : "高级搜索" }}</span>
              <i :class="`el-icon-arrow-${showMore?'up':'down'}`"></i>
            </el-button>
          </div>
          <el-input-number @keyup.enter.native="submitForm" v-else-if="field.widget == 'inputNumber'" v-model="params[field.key]"
                    :placeholder="field.placeholder" v-bind="field.options"></el-input-number>
          <el-input @keyup.enter.native="submitForm" v-else v-model="params[field.key]" :placeholder="field.placeholder"></el-input>
      </div>
    </div>
    <div class="form-operate-btn" v-if="operateBtns && operateBtns.length > 0">
      <el-button v-for="(option, pIndex) in operateBtns"
        :key="pIndex" :type="option.theme"
        v-show="option.show"
        @click="$emit(option.emit, option)">{{option.label}}</el-button>
      <el-button v-if="(renderFieldList.length > lineMax)" type="text" @click="toggleMore">
          <span> {{ showMore ? "收起" : "高级搜索" }}</span>
          <i :class="`el-icon-arrow-${showMore?'up':'down'}`"></i>
      </el-button>
    </div>
  </div>
</template>
<script>
import SchemaParser, { getDefaultValueByWidget } from './parseFormOptions';
import InputRange from './module/inputRange.vue';

export default {
  name: 'SmartFormRender',
  components: {
    InputRange,
  },
  props: {
    options: {
      type: Object,
      require: true,
    },
    params: {
      type: Object,
      require: true,
    },
    labelWidth: {
      type: String,
      default: '80px',
    },
  },
  data() {
    let config = new SchemaParser(this.options, this.params)
    config.showMore = false
    config.lineMax = 5
    this.config = config;

    return config;
  },
  watch: {
    params: {
      immediate: true,
      deep: true,
      handler() {
        this.config.execLogic();
      },
    },
  },
  computed: {
    renderFieldList() {
      if (!this.fieldList) return [];
      return this.fieldList.filter((item) => !item.hidden);
    },
  },
  methods: {
    submitForm() {
      this.$emit('submit', {
        label: '查询',
        id: 'search',
        emit: 'submit',
      });
    },
    toggleMore() {
      this.showMore = !this.showMore
    },
    toggleField(key, propertyObj) {
      this.fieldList.find(field => {
        if (field.key === key) {
          if (propertyObj.hidden !== undefined) {
            field.hidden = propertyObj.hidden;
          }
          if (propertyObj.disable !== undefined) {
            field.disable = propertyObj.disable;
          }
          return true;
        }
        return false;
      })
    },
    initParams() {
      const params = this.params;
      this.fieldList.forEach((fileItem) => {
        if (fileItem.key === undefined) return;
        if (params[fileItem.key] !== undefined) {
        // 对于已经有默认值的情况直接跳过
          return;
        }
        let fieldDefaultData = getDefaultValueByWidget(fileItem.widget);
        if (typeof fileItem.default === 'function') {
          fieldDefaultData = fileItem.default();
        } else if (fileItem.default !== undefined && fileItem.default !== null) {
          fieldDefaultData = fileItem.default;
        }
        this.$set(params, fileItem.key, fieldDefaultData);
      });
    },
    reset(defaultParmas) {
      const params = this.params;
      this.fieldList.forEach((fileItem) => {
        if (fileItem.key === undefined) return;
        if (defaultParmas[fileItem.key] !== undefined) {
          this.$set(params, fileItem.key, defaultParmas[fileItem.key]);
          return;
        }
        let fieldDefaultData = getDefaultValueByWidget(fileItem.widget);
        if (typeof fileItem.default === 'function') {
          fieldDefaultData = fileItem.default();
        } else if (fileItem.default !== undefined && fileItem.default !== null) {
          fieldDefaultData = fileItem.default;
        }
        this.$set(params, fileItem.key, fieldDefaultData);
      });
    },
  },
  created() {
    // this.config = new SchemaParser(this.options, this.params);
    // console.log(this, 'this.config created');
  },
  mounted() {
    this.lineMax = Math.floor(document.querySelector('.form-container').offsetWidth/315)
  }
};
</script>
<style lang="scss" scoped>
.smart-form-render {
  .form-container {
    display: flex;
    flex-wrap: wrap;
  }
  .col-block {
    display: flex;
    align-items: center;
    padding: 4px 0;
    width: 315px;
  }
  .col-block.hide{
    display: none;
  }
  .span-24{
    width: 100%;
    .form-label + div {
      width: unset;
    }
  }
  .form-label {
    width: 80px;
    font-size: 14px;
    color: #606266;
    line-height: 40px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
    text-align: right;
    & + div {
      width: 200px;
    }
  }
  .form-item-btns {
    text-align: left;
    width: 100%;
    padding-left: 20px;
    white-space: nowrap;
    .form-item-btns{
      padding-left: 0;
    }
  }

  .form-operate-btn {
    text-align: left;
    box-sizing: border-box;
    padding-left: 80px;
    width: 100%;
    padding-top: 10px;
  }
}
</style>
