export default {
  runningTypes: [
    {
      value: 1,
      label: '帮取',
      name: '帮我取',
      flag: 'bang_qu',
    },
    {
      value: 2,
      label: '帮送',
      name: '帮我送',
      flag: 'bang_song',
    },
    {
      value: 3,
      label: '帮买',
      name: '帮我买',
      flag: 'bang_mai',
    },
    {
      value: 4,
      label: '跑腿',
      name: '跑腿',
      flag: 'pao_tui'
    },
    {
      value: 5,
      label: '大件',
      name: '大件',
      flag: 'da_jian',
    },
    {
      value: 6,
      label: '帮排',
      name: '帮排队',
      flag: 'bang_pai'
    },
    {
      value: 7,
      label: '全能帮手',
      name: '全能帮手',
      flag: 'quan_neng'
    }
  ],
};
