var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-container-hidden"},[_c('div',{staticClass:"main-container flexbox flex-lr"},[_c('div',{staticClass:"main-container-first-menu main-position-first"},[_c('div',{staticClass:"main-container-logo"},[_c('img',{attrs:{"src":_vm.$imgPrefix(_vm.$store.state.system_cfg.platformLogo, '/public/joint_food/admin/login-icon.png'),"alt":"logo"}})]),_c('ul',{staticClass:"main-container-first-menu-list"},_vm._l((_vm.menuList),function(menuItem){return _c('li',{key:menuItem.name,class:_vm.currentActive &&
            _vm.currentActive.name === menuItem.name &&
            'main-container-first-menu-active',on:{"click":function($event){return _vm.checkedFirstMenu(menuItem)}}},[(menuItem.icon)?_c('i',{class:menuItem.icon}):_vm._e(),_c('span',[_vm._v(_vm._s(menuItem.title))])])}),0)]),_c('div',{staticClass:"main-container-right flexbox flex-tb"},[_c('div',{staticClass:"\n          main-container-header\n          flexbox\n          flex-lr flex-align-center flex-justify-between\n        "},[_c('div',{staticClass:"main-container-header-tab"},_vm._l((_vm.tabList),function(tag){return _c('el-tag',{key:tag.name,attrs:{"closable":_vm.tabList.length > 1},on:{"close":function($event){return _vm.handleCloseTab(tag)},"click":function($event){return _vm.toPage(tag)}}},[_vm._v(" "+_vm._s(tag.title)+" ")])}),1),_c('div',{staticClass:"flexbox flex-lr main-container-header-right"},[_c('el-badge',{attrs:{"value":_vm.unreadCount,"max":99,"hidden":_vm.unreadCount>0?false:true}},[_c('i',{staticClass:"el-icon-message",on:{"click":function($event){_vm.noticeVisiable=true}}})]),_c('el-dropdown',{staticClass:"main-container-header-dropdown",on:{"command":_vm.handleCommand}},[_c('span',{staticClass:"el-dropdown-link"},[_vm._v(" "+_vm._s(_vm.$store.state.userInfo.name)+" "),_c('i',{staticClass:"el-icon-arrow-down el-icon--right"})]),_c('el-dropdown-menu',{staticClass:"main-container-dropdown-list",attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":"changePass"}},[_vm._v("修改密码")]),_c('el-dropdown-item',{attrs:{"command":"logout"}},[_vm._v("退出登录")]),(_vm.$hasAuthRoute('RecycleBin'))?_c('el-dropdown-item',{attrs:{"command":"recycleBin"}},[_vm._v("回收站")]):_vm._e()],1)],1)],1)]),_c('div',{staticClass:"flexbox flex-lr main-container-wrap"},[(
            _vm.currentActive &&
            _vm.currentActive.children &&
            _vm.currentActive.children.length
          )?_c('div',{staticClass:"main-position-secondary"},[_c('ul',{staticClass:"main-container-secondary-menu"},_vm._l((_vm.currentActive.children),function(item){return _c('li',{key:item.name,staticClass:"main-container-secondary-menu-item",class:{
                'main-container-secondary-menu-active':
                  _vm.currentActiveItem && _vm.currentActiveItem.name === item.name,
              },on:{"click":function($event){return _vm.jumpToPage(item)}}},[_vm._v(" "+_vm._s(item.title)+" ")])}),0)]):_vm._e(),_c('div',{staticClass:"flex_1 main-container-content",attrs:{"id":_vm.$route.name}},[_c('keep-alive',{attrs:{"include":_vm.keepAliveCompList,"max":10}},[_c('router-view',{staticStyle:{"width":"100%","height":"100%","overflow-y":"auto"}})],1),_c('MenuBar')],1),_c('NoticeBar',{attrs:{"visible":_vm.noticeVisiable},on:{"close":_vm.closeNoticeBar}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }