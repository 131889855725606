import { deepClone } from '@smart/util-base';
import FormWidget from '../widget/FormWidget';

function parseSearchField(field, params) {
  const newFields = field.map((fieldItem) => {
    if (typeof fieldItem === 'string') {
      const [key, label, widget = 'input', placeholder, id] = fieldItem.split('|');
      return new FormWidget(widget, key, id, placeholder);
    } else if (Array.isArray(fieldItem)) {
      const [key, label, widget = 'input', placeholder, id] = fieldItem;
      return new FormWidget(widget, key, id, label, placeholder);
    }
    const newFieldItem = deepClone(fieldItem);
    console.log('newFieldItem', newFieldItem);
    const formWidget = new FormWidget(
      newFieldItem.widget, newFieldItem.key,
      newFieldItem.id,
      newFieldItem.label, newFieldItem.placeholder,
      newFieldItem.customProps, newFieldItem.slotName, newFieldItem.slotLabel,
      newFieldItem.span, newFieldItem.option,
      newFieldItem.hidden, newFieldItem.disable, newFieldItem.handle
    );
    if (newFieldItem.getOptions) {
      const endRes = newFieldItem.getOptions(newFieldItem);
      if (endRes.then) {
        endRes.then((resOptions) => {
          formWidget.optionsData = resOptions;
          if (newFieldItem.defaultData) {
            // eslint-disable-next-line no-param-reassign
            params[formWidget.key] = newFieldItem.defaultData(resOptions);
          }
        });
      } else {
        formWidget.optionsData = endRes;
      }
    }
    return formWidget;
  });

  return newFields;
}

export function getDefaultValueByWidget(widget) {
  switch (widget) {
    case 'checkbox': return [];
    case 'inputRange': return [];
    case 'daterange': return [];
    default: return '';
  }
}


export default class SchemaParser {
  fieldList = [];

  operateBtns = [];

  formparams = {};

  constructor(options, params) {
    this.formparams = params;
    this.fieldList = parseSearchField(options.fields, params);
    this.operateBtns = options.operateBtns?options.operateBtns.map(e=>{
      return {
        ...e,
        show: typeof e.show == 'boolean' ? e.show : true
      }
    }):[];
    this.logic = deepClone(options.logic);
  }


  execLogic() {
    if (!this.logic || this.logic.length === 0) return;
    const params = this.formparams;
    const fieldList = this.fieldList;
    const operateBtns = this.operateBtns;
    this.logic.forEach((logicItem) => {
      // console.log(logicItem.condition, this.formparams);
      // eslint-disable-next-line
      const conditionEnd = eval(logicItem.condition);
      if (Array.isArray(logicItem.behavior)) {
        logicItem.behavior.forEach((behaviorItem) => {
          const [id, operate, value] = behaviorItem;
          this.logicToField(id, operate, value, conditionEnd);
        });
      }
    });
  }

  logicToField(id, operate, value, conditionEnd) {
    if (conditionEnd && value[0] === undefined) return;
    if (!conditionEnd && value[1] === undefined) return;
    // console.log(id, operate, value, conditionEnd);
    const allField = [...this.fieldList, ...this.operateBtns];
    // console.log(allField);
    const fieldItem = allField.find((field) => (field && field.id === id));
    if (!fieldItem) return;
    switch (operate) {
      case 'hidden': fieldItem.hidden = conditionEnd ? value[0] : value[1]; break;
      case 'disable': fieldItem.disable = conditionEnd ? value[0] : value[1]; break;
      case 'clean':
        if (!conditionEnd) break;
        this.formparams[fieldItem.key] = getDefaultValueByWidget(fieldItem.widget);
        break;
      case 'setValue':
        this.formparams[fieldItem.key] = conditionEnd ? value[0] : value[1]; break;
      default:
    }
  }
}
