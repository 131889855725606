import BaseWidget from './BaseWidget';

export default class TableWidget extends BaseWidget {
  // (type, prop, option, label, width, align)
  show = true;
  
  constructor(column) {
    const defaultKeys = ['type', 'prop', 'option', 'label', 'width', 'align'];
    super(column.prop); // key
    Object.keys(column).forEach((itemKey) => {
      if (!defaultKeys.includes(itemKey)) {
        this[itemKey] = column[itemKey];
      } else if (itemKey !== 'option') {
        this.option[itemKey] = column[itemKey];
      } else {
        this.option = { ...this.option, ...column.option || {} };
      }
    });
  }
}
