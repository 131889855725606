import PrevReclick from './lib/prevReClick';
import SmartFormRender from './lib/formRender/SmartFormRender.vue';
import SmartTableRender from './lib/tableRender/SmartTableRender.vue';

export { default as PrevReclick } from './lib/prevReClick';
export { default as toSingletonPattern } from './lib/toSingletonPattern';


export default {
  install(Vue, options = {  }) {
    Vue.use(PrevReclick, options.prevReclick);
    if (options.registerUi === undefined || options.registerUi === true) {
      Vue.component('SmartFormRender', SmartFormRender);
      Vue.component('SmartTableRender', SmartTableRender);
    }

  },
};
