let lastId = 1;

export default class BaseWidget {
  id = 0; // widget的id

  key = ''; // 数据绑定key

  // slot input select cascader radio checkbox inputRange date
  widget = ''; // 渲染的widget类型，这里仅示例

  hidden = false; // 是否隐藏

  disable = false; // 是否禁用

  option = {} // 当前widget特有属性，所有特有属性都应放在此对象内

  constructor(key, id) {
    if (id) {
      this.id = id;
    } else {
      this.id = lastId;
      lastId += 1;
    }

    this.key = key;
  }
}
